@import "vendor/sweetalert2.css";
#main-content {
  opacity: 1;
}
@font-face {
  font-family: '3dumbregular';
  src: url('/SiteFiles/src/ui/fonts/3Dumb-webfont.eot');
  src: url('/SiteFiles/src/ui/fonts/3Dumb-webfont.eot?#iefix') format('embedded-opentype'), url('/SiteFiles/src/ui/fonts/3Dumb-webfont.woff') format('woff'), url('/SiteFiles/src/ui/fonts/3Dumb-webfont.ttf') format('truetype'), url('/SiteFiles/src/ui/fonts/3Dumb-webfont.svg#3dumbregular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/*import CSS Pages*/
.alert-banner {
  background-color: #cb2f30;
}
.alert-banner__inner {
  text-align: center;
  padding: 15px 10px;
}
@media (max-width: 768px) {
  .alert-banner__inner {
    padding: 10px;
  }
}
.alert-banner h2,
.alert-banner p {
  color: #fff;
  line-height: 1;
}
.alert-banner h2 {
  background: none;
  padding: 0;
  margin: 0px 0px 5px 0px;
}
.alert-banner p {
  margin: 5px;
}
.alert-banner p a {
  color: #fff;
  font-weight: bold;
}
.replacement-covers {
  background-color: #2c5d40;
  padding: 15px;
  margin-bottom: 15px;
}
.replacement-covers h3 {
  color: #fff;
  text-transform: uppercase;
  font-size: 1.5714em;
  margin-bottom: 10px;
}
.replacement-covers .chzn-container {
  display: block;
}
.replacement-covers .cover-left {
  float: left;
  width: 53%;
  margin-right: 2%;
}
.replacement-covers .cover-left p {
  color: #fff;
  padding-left: 0px;
  font-size: 12px;
  margin-bottom: 2px;
}
.replacement-covers .cover-left p img {
  margin: 0px 5px 0 0;
  border-radius: 5px;
}
.replacement-covers .cover-right {
  float: left;
  width: 45%;
}
.replacement-covers .cover-right.full {
  float: none;
  width: 100%;
}
.replacement-covers .cover-split {
  display: flex;
  width: 100%;
  gap: 20px;
}
.replacement-covers .cover-split > div {
  flex-basis: 50%;
  width: 50%;
}
.replacement-covers .cover-split .back-btn {
  margin-top: auto;
}
.replacement-covers .cover-split .form-control-wrapper {
  width: 100%;
}
.replacement-covers .cover-split input {
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 2px;
  font-size: 0.8285em;
  color: #00421b;
}
@media screen and (max-width: 767px) {
  .replacement-covers .cover-split input:focus,
  .replacement-covers .cover-split input:active {
    font-size: 16px;
  }
}
.replacement-covers input {
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 2px;
  color: #00421b;
}
.replacement-covers input[type='text'] {
  color: #00421b !important;
}
.replacement-covers select {
  margin-bottom: 2px;
  border-radius: 0;
  box-shadow: none;
}
.replacement-covers .chzn-container-single .chzn-single span {
  font-size: 1.15em;
}
@media (min-width: 768px) {
  .replacement-covers .chzn-container-single .chzn-single span {
    font-size: 0.9285em;
  }
}
.replacement-covers input[placeholder],
.replacement-covers .placeholder {
  color: #627168;
  opacity: 1;
}
.replacement-covers ::-webkit-input-placeholder {
  color: #627168;
  opacity: 1;
}
.replacement-covers :-moz-placeholder {
  /* Firefox 18- */
  color: #627168;
  opacity: 1;
}
.replacement-covers ::-moz-placeholder {
  /* Firefox 19+ */
  color: #627168;
  opacity: 1;
}
.replacement-covers :-ms-input-placeholder {
  color: #627168;
  opacity: 1;
}
.replacement-covers .split-columns {
  display: flex;
  gap: 20px;
  clear: both;
}
.replacement-covers .split-columns > div {
  flex-basis: 50%;
}
.replacement-covers .split-columns > div input[type='submit'] {
  margin-top: 0;
  margin-bottom: 8px;
}
.replacement-covers input[type='submit'] {
  width: auto;
  background: #7d9c86 url(/SiteFiles/img/svg/angle-right-icon.svg) no-repeat right 12px center;
  color: #fff;
  border: none;
  text-align: center;
  font-size: 16px;
  padding: 0 10px;
  height: 34px;
}
.replacement-covers input[type='submit']:hover {
  background-color: #c5862d;
}
.replacement-covers input[type='submit'].back {
  background: #7d9c86 url(/SiteFiles/img/svg/angle-left-icon.svg) no-repeat left 10px center;
  padding-left: 26px;
  padding-right: 12px;
}
.replacement-covers input[type='submit'].back:hover {
  background-color: #c5862d;
}
.replacement-covers input[type='submit'].add {
  background-color: #cb3030;
  background-image: none;
}
.replacement-covers input[type='submit'].add:hover {
  background-color: #e43535;
}
.replacement-covers input[type='submit'].update {
  background-image: none;
}
.replacement-covers input[type='submit'].update.with-arrow {
  background: #7d9c86 url(/SiteFiles/img/svg/angle-right-icon.svg) no-repeat right 10px center;
  padding-right: 24px;
}
.replacement-covers input[type='submit'].center {
  text-align: center;
}
.replacement-covers input[type='submit'].full {
  width: 100%;
}
.replacement-covers input[type='submit'].half {
  width: 49%;
  margin-bottom: 5px;
}
.replacement-covers input[type='submit'].left {
  float: left;
}
.replacement-covers input[type='submit'].right {
  float: right;
}
.replacement-covers strong {
  color: #fff;
  font-weight: normal;
  font-size: 16px;
}
.replacement-covers p {
  color: #fff;
  font-size: 12px;
  margin: 0;
}
.replacement-covers p.choose-other {
  font-size: 0.9em;
  line-height: 1.3em;
  margin-bottom: 10px;
}
.replacement-covers p.cover {
  font-size: 0.9em;
  margin: 4px 0 10px 0;
  line-height: 1.4em;
}
.replacement-covers p.summary {
  line-height: 1.3em;
  margin: 4px 0 5px 0;
}
.replacement-covers p.select,
.replacement-covers p.select-total {
  float: left;
  margin: 7px 0;
  font-size: 1em;
}
.replacement-covers p.select-total {
  line-height: 2.8;
  margin-left: 8px;
}
.replacement-covers p.note {
  margin: 10px -15px -15px;
  padding: 15px;
  background-color: #bfd3c4;
  color: #000;
  font-size: 12px;
}
.replacement-covers .update {
  margin-top: 10px;
}
.replacement-covers .form-control-wrapper {
  position: relative;
  margin-bottom: 2px;
  font-size: 1.15em;
}
@media (min-width: 768px) {
  .replacement-covers .form-control-wrapper {
    font-size: 0.8285em;
  }
}
.replacement-covers .form-control-wrapper input,
.replacement-covers .form-control-wrapper .chzn-container {
  margin-bottom: unset;
}
.replacement-covers .form-control-wrapper .update {
  width: 100%;
  text-align: center;
}
.replacement-covers .form-control-wrapper select {
  height: 28px;
  padding: 0 2px;
}
.replacement-covers .errorRepl {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border: #d9000d solid 2px;
  z-index: 100;
  pointer-events: none;
}
.replacement-covers .quantity {
  width: 40px;
  text-align: center;
  margin: 5px 0;
}
.premier-promise p {
  background: url(/SiteFiles/img/tick.png) no-repeat left;
  padding: 0 0 0 35px;
  line-height: 30px;
  font-weight: bold;
  display: none;
  font-size: 1.1428em;
  text-transform: uppercase;
  font-family: 'Averia Sans Libre', sans-serif;
}
.premier-promise img {
  max-height: 370px;
}
.growing-guide {
  position: relative;
  padding: 15px 20px;
  margin-top: 5px;
  margin-bottom: 35px;
}
.growing-guide .clip {
  background: url(/SiteFiles/img/sprite.png) -244px 0 no-repeat;
  width: 56px;
  height: 76px;
  display: block;
  position: absolute;
  top: -23px;
  right: -25px;
}
.growing-guide h2 {
  background: none;
  line-height: normal;
  color: #00421b;
  padding: 0;
  font-size: 1.5714em;
  filter: none;
  -ms-filter: none;
}
.growing-guide p {
  color: #000;
  font-size: 15px;
  width: 85%;
}
.growing-guide a {
  color: #00421b;
  font-family: 'Averia Sans Libre', sans-serif;
  text-transform: uppercase;
  display: block;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 20px;
}
.growing-guide a:hover {
  color: #5d7e32;
}
.growing-guide .grow-over {
  z-index: 10;
  position: relative;
}
.growing-guide .post-it {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: #fce759;
  /* Old browsers */
  background: -moz-linear-gradient(top, #fce759 0%, #fdee8a 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fce759), color-stop(100%, #fdee8a));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #fce759 0%, #fdee8a 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #fce759 0%, #fdee8a 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #fce759 0%, #fdee8a 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #fce759 0%, #fdee8a 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fce759', endColorstr='#fdee8a', GradientType=0);
  /* IE6-9 */
  z-index: 0;
  -moz-transform: rotate(2deg);
  -webkit-transform: rotate(2deg);
  -ms-transform: rotate(2deg);
  -o-transform: rotate(2deg);
  transform: rotate(2deg);
  box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.25);
}
.growing-guide .guide-bottom {
  min-height: 80px;
  background: url(/SiteFiles/img/post-it-can.png) no-repeat;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  padding-right: 25px;
}
.growing-guide .guide-bottom span {
  float: left;
  font-family: 'Averia Sans Libre', sans-serif;
  color: #00421b;
  margin: 16% 0 0 38%;
  font-size: 1.4em;
}
.IE8 .growing-guide .clip {
  top: -28px !important;
}
@media (max-width: 767px) {
  .post-it {
    -moz-transform: none !important;
    -webkit-transform: none !important;
    -ms-transform: none !important;
    -o-transform: none !important;
    transform: none !important;
  }
  .premier-promise img {
    display: none;
  }
  .premier-promise p {
    display: block;
  }
}
@media (max-width: 480px) {
  .replacement-covers .cover-left {
    float: none;
    width: 100%;
    margin-right: 0%;
    margin-bottom: 2%;
    text-align: center;
  }
  .replacement-covers .cover-left .image-key {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .replacement-covers .cover-left .image-key > div {
    flex-basis: 50%;
    text-align: left;
  }
  .replacement-covers .cover-right {
    float: none;
    width: 100%;
    margin-right: 0%;
  }
  .replacement-covers .form-control-wrapper {
    margin-bottom: 8px;
  }
  .replacement-covers .split-columns input[type='submit'],
  .replacement-covers .form-control-wrapper input[type='submit'],
  .replacement-covers .split-columns input[type='submit'].full,
  .replacement-covers .form-control-wrapper input[type='submit'].full,
  .replacement-covers .split-columns .btn,
  .replacement-covers .form-control-wrapper .btn {
    width: 100%;
  }
  .replacement-covers .chzn-container-single .chzn-single {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .replacement-covers .chzn-container-single .chzn-single span {
    margin: 0;
  }
  .replacement-covers .chzn-container-single .chzn-single .split-columns input[type='submit'],
  .replacement-covers .chzn-container-single .chzn-single .form-control-wrapper input[type='submit'],
  .replacement-covers .chzn-container-single .chzn-single .split-columns input[type='submit'].full,
  .replacement-covers .chzn-container-single .chzn-single .form-control-wrapper input[type='submit'].full,
  .replacement-covers .chzn-container-single .chzn-single .split-columns .btn,
  .replacement-covers .chzn-container-single .chzn-single .form-control-wrapper .btn {
    width: 100%;
  }
  .replacement-covers .chzn-container-single .chzn-single .chzn-container-single .chzn-single {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .replacement-covers .chzn-container-single .chzn-single .chzn-container-single .chzn-single span {
    margin: 0;
  }
}
.services {
  padding-right: 30px;
  margin-bottom: 35px;
}
.services img {
  margin-bottom: 15px;
}
.services p {
  padding-right: 10px;
}
@media (max-width: 767px) {
  .services {
    padding-right: 0px;
  }
  .services p {
    padding-right: 0px;
  }
}
.blog-page {
  padding-bottom: 20px;
}
.blog-page .blog-column {
  padding-bottom: 20px;
  border-bottom: 3px solid #9c9a7d;
}
.blog-page h1 {
  display: block;
  margin-bottom: 35px;
}
.blog-page h4 {
  font-family: 'Averia Sans Libre', sans-serif;
  color: #61614b;
  font-size: 1.5714em;
  text-transform: uppercase;
  font-weight: normal;
  margin-bottom: 15px;
}
.blog-page p {
  color: #3d3d37;
}
.blog-page ul {
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: 'Averia Sans Libre', sans-serif;
}
.blog-page ul a {
  color: #61614b;
}
.blog-page ul a:hover {
  color: #be7915;
}
.blog-page .blog-entry {
  margin-bottom: 35px;
}
.blog-page .blog-entry h5 {
  display: block;
  padding-top: 10px;
  border-top: 3px solid #9c9a7d;
  font-size: 1em;
  font-weight: normal;
  font-family: Arial, sans-serif;
  color: #3d3d37;
  margin-top: 10px;
}
.blog-page .blog-entry img {
  margin-bottom: 20px;
}
.blog-page .blog-entry .blog-top {
  border-bottom: 3px solid #9c9a7d;
  position: relative;
  padding-left: 73px;
  padding-bottom: 5px;
  min-height: 70px;
  margin-bottom: 15px;
}
.blog-page .blog-entry .blog-top h2 {
  background: none;
  line-height: normal;
  color: #61614b;
  font-size: 1.4285em;
  padding: 0;
  font-weight: normal;
  padding-top: 5px;
  filter: none;
  -ms-filter: none;
}
.blog-page .blog-entry .blog-top .date {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  background: #9c9a7d;
  text-align: center;
  color: #fff;
  padding: 5px 10px;
  font-size: 1.2142em;
  font-family: 'Averia Sans Libre', sans-serif;
  line-height: 100%;
  text-transform: uppercase;
}
.blog-page .blog-entry .blog-top .date span {
  display: block;
}
.blog-page .blog-entry .blog-top .date .date-no {
  font-size: 1.5294em;
}
.blog-page .blog-entry .blog-top .date .date-year {
  font-size: 0.70588em;
}
.blog-page .blog-entry .blog-content {
  max-height: 200px;
  overflow: hidden;
  position: relative;
}
.blog-page .blog-entry .blog-content:after {
  content: '';
  width: 100%;
  height: 15px;
  position: absolute;
  bottom: 0;
  right: 0;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 1%, #ffffff 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(1%, rgba(255, 255, 255, 0)), color-stop(100%, #ffffff));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 1%, #ffffff 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 1%, #ffffff 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 1%, #ffffff 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 1%, #ffffff 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
  /* IE6-9 */
}
.blog-page .blog-reply .comment-submit {
  font-weight: bold;
  line-height: 22px;
  margin-bottom: 10px;
  display: block;
}
.blog-page .blog-reply .comment-submit.success {
  color: #53ae3d;
}
.blog-page .blog-reply .comment-submit.fail {
  color: #cc0000;
}
.blog-page .blog-reply .comment-icon {
  width: 26px;
  height: 23px;
  display: block;
  background: url(/SiteFiles/img/sprite.png) -75px -121px no-repeat;
  position: absolute;
  top: 19px;
  left: 15px;
}
.blog-page .blog-reply .reply-top.comments {
  padding-left: 47px;
}
.blog-page .blog-reply .reply-top {
  position: relative;
  padding: 15px 50px 15px 15px;
  background: #9c9a7d;
  cursor: pointer;
  margin-bottom: 3px;
}
.blog-page .blog-reply .reply-top h4 {
  margin: 0;
  color: #fff;
  font-size: 1.4285em;
  font-weight: normal;
  line-height: normal;
}
.blog-page .blog-reply .reply-top .down-arrow {
  background: url(/SiteFiles/img/sprite.png) 0 -124px no-repeat;
  width: 19px;
  height: 15px;
  display: block;
  position: absolute;
  top: 20px;
  right: 15px;
}
.blog-page .blog-reply .reply-top .up-arrow {
  background: url(/SiteFiles/img/sprite.png) -30px -124px no-repeat;
  width: 19px;
  height: 15px;
  display: block;
  display: none;
  position: absolute;
  top: 15px;
  right: 15px;
}
.blog-page .blog-reply .reply-main {
  display: none;
}
.blog-page .blog-reply .reply-main .comment-errors {
  margin: 5px 0;
}
.blog-page .blog-reply .reply-main .comment-errors span {
  float: left;
  width: 100%;
  font-size: 90%;
  color: #cc0000;
  line-height: 120%;
}
.blog-page .blog-reply .reply-main input {
  background: #9c9a7d;
  color: #fff;
  border-radius: 0;
  border: none;
  box-shadow: none;
  height: auto;
  padding: 15px;
  font-size: 1em;
  width: 50%;
  margin: 0 0 3px 0;
  display: block;
  float: left;
}
.blog-page .blog-reply .reply-main input:first-child {
  border-right: 3px solid #fff;
}
.blog-page .blog-reply .reply-main textarea {
  background: #9c9a7d;
  color: #fff;
  border-radius: 0;
  border: none;
  box-shadow: none;
  padding: 15px;
  font-size: 1em;
  margin-bottom: 3px;
}
.blog-page .blog-reply .reply-main input[type='submit'] {
  float: right;
  border: none;
  text-align: center;
  background: #53ae3d;
  font-size: 1.28571em;
}
.blog-page .blog-reply .reply-main input[type='submit']:hover {
  background: #be7915;
}
.blog-page .blog-reply .reply-main input[placeholder],
.blog-page .blog-reply .reply-main .placeholder,
.blog-page .blog-reply .reply-main input::-webkit-input-placeholder,
.blog-page .blog-reply .reply-main input:-moz-placeholder,
.blog-page .blog-reply .reply-main input::-moz-placeholder,
.blog-page .blog-reply .reply-main input:-ms-input-placeholder,
.blog-page .blog-reply .reply-main textarea[placeholder],
.blog-page .blog-reply .reply-main .placeholder,
.blog-page .blog-reply .reply-main textarea::-webkit-input-placeholder,
.blog-page .blog-reply .reply-main textarea:-moz-placeholder,
.blog-page .blog-reply .reply-main textarea::-moz-placeholder,
.blog-page .blog-reply .reply-main textarea:-ms-input-placeholder {
  color: #fff !important;
  opacity: 1 !important;
  font-size: 1em;
}
.blog-page .blog-reply .reply-main input::-moz-placeholder,
.blog-page .blog-reply .reply-main textarea::-moz-placeholder {
  color: #fff !important;
  opacity: 1 !important;
  font-size: 1em;
}
.blog-page .blog-reply .reply-main ::-webkit-input-placeholder {
  color: #fff !important;
  opacity: 1 !important;
  font-size: 1em;
}
.blog-page .blog-reply .reply-main :-moz-placeholder {
  /* Firefox 18- */
  color: #fff !important;
  opacity: 1 !important;
  font-size: 1em;
}
.blog-page .blog-reply .reply-main ::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff !important;
  opacity: 1 !important;
  font-size: 1em;
}
.blog-page .blog-reply .reply-main :-ms-input-placeholder {
  color: #fff !important;
  opacity: 1 !important;
  font-size: 1em;
}
.blog-page .blog-reply .reply-main .comments-area {
  padding: 15px;
}
.blog-page .blog-reply .reply-main .comments-area .comment-details p {
  font-weight: bold;
  position: relative;
  padding-right: 80px;
}
.blog-page .blog-reply .reply-main .comments-area .comment-details p .date {
  position: absolute;
  right: 0;
  top: 0;
}
.blog-reply.open-reply .down-arrow {
  display: none !important;
}
.blog-reply.open-reply .up-arrow {
  display: block !important;
}
.show-more {
  font-weight: bold;
  margin: 10px 0;
  display: none;
  cursor: pointer;
}
.blog-hidden {
  display: none;
}
@media (max-width: 480px) {
  .blog-page .blog-reply .reply-main input {
    float: none;
    width: 100%;
    border: none !important;
  }
}
.cat-banner {
  position: relative;
}
.cat-banner__content {
  position: absolute;
  top: 15px;
  left: 20px;
  max-width: 285px;
}
@media (max-width: 767px) {
  .cat-banner__content {
    top: 35px;
    max-width: 90%;
    left: 15px;
  }
}
@media (max-width: 468px) {
  .cat-banner__content {
    top: 15px;
  }
}
.cat-banner__title {
  color: #00421b;
  background: transparent;
  padding: 0;
  line-height: 24px;
  margin-bottom: 5px;
}
@media (max-width: 468px) {
  .cat-banner__title {
    font-size: 18px;
    line-height: 22px;
  }
}
.cat-banner__intro {
  color: #00421b;
  font-family: 'Averia Sans Libre', sans-serif;
  line-height: 16px;
}
@media (max-width: 767px) {
  .cat-banner__intro {
    font-size: 18px;
    line-height: 20px;
  }
}
@media (max-width: 468px) {
  .cat-banner__intro {
    font-size: 12px;
    line-height: 16px;
  }
}
/*windows phone fix*/
@-webkit-viewport {
  width: device-width;
}
@-moz-viewport {
  width: device-width;
}
@-ms-viewport {
  width: device-width;
}
@-o-viewport {
  width: device-width;
}
@viewport {
  width: device-width;
}
/*/*/
a,
a:hover,
a:active {
  outline: none;
}
::-moz-selection {
  background: #be7915;
  color: #fff;
  text-shadow: none;
}
::selection {
  background: #be7915;
  color: #fff;
  text-shadow: none;
}
.alCenter {
  text-align: center;
}
a {
  outline: none !important;
  color: #be7915;
  text-decoration: none;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}
a:hover,
a:focus {
  color: #e69520;
  text-decoration: none;
}
img {
  border: none;
  max-width: 100%;
}
label {
  font-weight: normal;
  color: #ff8826;
  font-size: 145%;
  margin-bottom: 15px;
}
.gap {
  margin-bottom: 35px;
}
.small-gap {
  margin-bottom: 15px;
}
input,
button,
select,
textarea {
  width: 100%;
  background: #fff;
  border: 1px solid #e3e3e3;
  padding: 5px;
  color: #000;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px #e3e3e3;
  height: 28px;
  outline: none !important;
}
select {
  width: 100%;
  height: 25px;
  padding: 0 5px;
}
textarea {
  margin-bottom: 20px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  color: #fff;
  font-size: 145%;
  height: 175px;
}
.form-control:focus {
  border-color: rgba(255, 136, 38, 0.8);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(255, 136, 38, 0.8);
  outline: 0 none;
}
.fRight {
  float: right !important;
  margin: 0 !important;
}
.fLeft {
  float: left !important;
  margin: 0 !important;
}
.alCenter {
  text-align: center;
}
.bold {
  font-weight: bold;
}
.normal {
  font-weight: normal;
}
.collapse {
  display: block;
  height: auto !important;
}
.navbar {
  min-height: 0;
  position: static;
  border: none;
  margin-bottom: 0;
}
.navbar input {
  margin-bottom: 0;
}
.desktop-only {
  display: none;
  visibility: hidden;
}
@media (min-width: 769px) {
  .desktop-only {
    display: block;
    visibility: visible;
  }
}
.mobile-only {
  display: none;
  visibility: hidden;
}
@media (max-width: 769px) {
  .mobile-only {
    display: block;
    visibility: visible;
  }
}
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-family: 'Averia Sans Libre', sans-serif;
  color: #00421b;
}
h1 {
  background: url(/SiteFiles/img/h1Bg.png) repeat-y;
  background-size: 100% 100%;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/SiteFiles/img/h1Bg.png', sizingMethod='scale');
  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader( src='/SiteFiles/img/h1Bg.png', sizingMethod='scale')";
  display: inline-block;
  clear: both;
  color: #fff;
  width: 100%;
  text-transform: uppercase;
  font-size: 1.5714em;
  line-height: 55px;
  padding: 0 15px;
  margin-bottom: 15px;
}
h2 {
  background: url(/SiteFiles/img/h1Bg.png) repeat-y;
  background-size: 100% 100%;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/SiteFiles/img/h1Bg.png', sizingMethod='scale');
  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader( src='/SiteFiles/img/h1Bg.png', sizingMethod='scale')";
  display: inline-block;
  clear: both;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.5714em;
  line-height: 55px;
  padding: 0 15px;
  margin-bottom: 15px;
}
html {
  position: relative;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
body {
  margin: 0;
  font-size: 14px;
  min-width: 270px;
  max-width: 100%;
  overflow-x: hidden;
  background: #ffffff;
  font-family: Arial, sans-serif;
  color: #00421b;
  position: relative;
  top: 0;
  bottom: 0;
  width: 100%;
}
#form {
  position: relative;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
#page-wrapper {
  position: relative;
  padding-bottom: 1px;
}
.lazy-load {
  opacity: 0;
  transition: opacity 0.25s ease;
  will-change: opacity;
}
.lazy-load.loaded {
  opacity: 1;
}
#main-content {
  position: relative;
  margin-bottom: 460px;
}
.main-banner {
  height: 400px;
  overflow: hidden;
  /*background: bottom no-repeat;*/
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  z-index: -1;
  background-position: bottom, center;
  background-size: cover;
}
.mainInner {
  background: #fff;
  padding: 10px 10px 0;
}
.breadcrumbs {
  margin-bottom: 15px;
  font-size: 1.1428em;
  font-weight: bold;
  font-family: 'Averia Sans Libre', sans-serif;
  color: #33684a;
}
.breadcrumbs a {
  color: #33684a;
}
.breadcrumbs a:hover {
  color: #be7915;
}
.breadcrumbs a:first-child {
  text-decoration: underline;
}
.comm-icons .active,
.comm-icons .active {
  background: #bd7610 !important;
}
.comm-icons {
  position: fixed;
  top: 220px;
  right: 0;
  display: block;
  z-index: 99;
}
.comm-icons .tel {
  background: #bd7610;
  padding: 15px;
  -moz-transition: background-color ease 0.3s;
  -o-transition: background-color ease 0.3s;
  -webkit-transition: background-color ease 0.3s;
  transition: background-color ease 0.3s;
  float: right;
  display: inline-block;
  width: auto;
}
.comm-icons .tel:hover {
  background: #223f2a;
}
.comm-icons .tel .telIcon {
  background: url(/SiteFiles/img/sprite.png) -101px -62px no-repeat;
  width: 51px;
  height: 36px;
  display: block;
  float: left;
  cursor: pointer;
}
.comm-icons .tel .numberArea {
  float: left;
  width: 0;
}
.comm-icons .tel .numberArea .number {
  width: 121px;
  height: 36px;
  display: block;
  color: #fff;
  font-weight: bold;
  font-size: 1.2857em;
  padding-top: 5px;
  margin-left: 20px;
}
.comm-icons .tel .numberArea.opennumber {
  width: auto;
}
.comm-icons .signup {
  background: #bd7610;
  padding: 10px 11px;
  -moz-transition: background-color ease 0.3s;
  -o-transition: background-color ease 0.3s;
  -webkit-transition: background-color ease 0.3s;
  transition: background-color ease 0.3s;
  float: right;
  display: inline-block;
  width: auto;
}
.comm-icons .signup:hover {
  background: #223f2a;
}
.comm-icons .signup .signupIcon {
  background: url(/SiteFiles/img/sprite.png) -173px -52px no-repeat;
  width: 59px;
  height: 59px;
  display: block;
  float: left;
  cursor: pointer;
}
.comm-icons .signup .signArea {
  float: left;
  width: 0;
  -webkit-transition: width 0.3s ease;
  -moz-transition: width 0.3s ease;
  -o-transition: width 0.3s ease;
  transition: width 0.3s ease;
}
.comm-icons .signup .signArea a {
  width: 121px;
  height: 36px;
  display: block;
  color: #fff;
  font-weight: normal;
  font-size: 1.2857em;
  margin-top: 15px;
  margin-left: 20px;
}
.comm-icons .signup .signArea a:hover {
  color: #223f2a;
}
.comm-icons .signup .signArea.opennumber {
  width: auto;
}
@keyframes tool-fall {
  0% {
    opacity: 0;
    transform: translateY(-500px);
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes tool-slide {
  0% {
    transform: translateX(-500px);
  }
  100% {
    transform: translateX(0px);
  }
}
.wheelbarrow {
  position: absolute;
  left: -398px;
  width: 398px;
  height: 215px;
  bottom: 0;
  background: url(/SiteFiles/img/wheelbarrow.png) no-repeat;
  animation: tool-slide 300ms 0ms 1 forwards paused;
}
.trowel {
  position: absolute;
  right: 89px;
  width: 124px;
  height: 348px;
  bottom: -25px;
  background: url(/SiteFiles/img/trowel.png) no-repeat;
  opacity: 0;
  animation: tool-fall 150ms 300ms 1 forwards paused;
}
.fork {
  position: absolute;
  right: -150px;
  bottom: -40px;
  width: 154px;
  height: 332px;
  background: url(/SiteFiles/img/fork.png) no-repeat;
  opacity: 0;
  animation: tool-fall 150ms 800ms 1 forwards paused;
}
.left-nav {
  margin-bottom: 15px;
}
.left-nav .left-nav-heading {
  position: relative;
  padding-right: 27px;
}
.left-nav .left-nav-heading .start {
  background: #c5862d;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
  width: 40px;
  position: absolute;
  left: -40px;
  top: 0;
  height: 100%;
  display: block;
  box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.25);
}
.left-nav .left-nav-heading h2 {
  display: block;
  position: relative;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.285em;
  padding: 12px 10px 12px 20px;
  background: #c5862d;
  text-align: left;
  box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.25);
  cursor: pointer;
  line-height: normal;
  margin: 0;
  filter: none;
  -ms-filter: none;
}
.left-nav .left-nav-heading .end {
  background: url(/SiteFiles/img/leftnavheading.png) no-repeat;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/SiteFiles/img/leftnavheading.png', sizingMethod='scale');
  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader( src='/SiteFiles/img/leftnavheading.png', sizingMethod='scale')";
  width: 27px;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: block;
}
.left-nav ul {
  list-style: none;
  margin: 10px 0;
  padding: 0 0 0 20px;
  display: none;
}
.left-nav ul li {
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
  padding: 5px 0;
}
.left-nav ul li a {
  color: #151515;
}
.left-nav ul li a:hover {
  color: #f58025;
}
.ajax-loader {
  position: fixed;
  text-align: center;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9999999;
  background-color: #000000;
  opacity: 0.7;
}
.ajax-loader span {
  border-width: 0px;
  position: fixed;
  padding: 50px;
  background-color: #ffffff;
  font-size: 36px;
  left: 40%;
  top: 40%;
}
@media (max-width: 1400px) {
  .trowel {
    display: none;
  }
}
@media (max-width: 1140px) {
  .main-banner {
    display: none;
  }
  .comm-icons {
    display: none;
  }
}
@media (max-width: 991px) {
  .comm-icons {
    display: block;
  }
  .main-banner {
    display: block;
  }
  h1 {
    font-size: 1.1714em;
  }
}
@media (max-width: 899px) {
  .comm-icons,
  .main-banner {
    display: none;
  }
}
@media (max-width: 1024px) {
  #main-content {
    margin-bottom: 0;
  }
}
@media (max-width: 991px) {
  .main-banner {
    display: none;
  }
  .navbar-toggle {
    background: none;
    border: none;
    margin: 0;
    padding: 6px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    box-shadow: none;
    height: 35px;
    position: absolute;
    left: -90px;
    float: none;
    top: 0;
    text-align: center;
    color: #fff;
    font-weight: bold;
    width: 90px;
    display: block;
  }
  .navbar-toggle:hover,
  .navbar-toggle:active,
  .navbar-toggle:focus {
    background: none;
    color: #f58025;
  }
  html {
    overflow-x: hidden;
  }
  a.fblike,
  a.twitter,
  a.instagram,
  a.youtubeicon {
    display: none !important;
  }
  .collapse {
    display: none;
  }
  .wheelbarrow,
  .trowel,
  .fork {
    display: none !important;
  }
  .ajax-loader span {
    width: 250px;
    margin-left: -75px;
  }
}
@media (max-width: 480px) {
  h1,
  h2 {
    background: none;
    color: #00421b;
    line-height: normal;
    padding: 0;
  }
  .navbar-toggle {
    text-align: right;
    padding-right: 13px;
  }
}
.home-seo-header {
  display: block;
  margin: 25px 0;
  cursor: pointer;
}
.home-seo-header h3 {
  font-size: 1.2em;
}
.home-seo {
  display: none;
  font-size: 0.9em;
}
.home-seo h3 {
  font-size: 1.2em;
}
.home-seo .minimise {
  cursor: pointer;
  font-weight: bold;
  margin-top: 20px;
  font-size: 1.1em;
  display: block;
  text-decoration: underline;
}
.home-seo + .row {
  margin-bottom: 40px;
}
.product-category {
  min-height: 400px;
  margin-bottom: 30px;
}
.product-category .product {
  margin-bottom: 15px;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 3px 6px;
}
.product-category .product .product-img {
  position: relative;
  display: block;
  padding-bottom: 71%;
}
.product-category .product .product-img img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
.product-category .product .product-details {
  padding: 10px;
  height: 148px;
}
.product-category .product .product-view-more {
  position: absolute;
  bottom: 25px;
}
.product-category .product a {
  font-size: 16px;
  font-weight: normal;
  color: #153f25;
  display: block;
}
.product-category .product a:hover,
.product-category .product a:focus {
  color: #c5862d;
}
.product-category .product span {
  display: block;
  font-size: 16px;
  color: #326949;
  margin: 6px 0 18px;
}
.product-category .product .btn {
  display: inline-block;
  font-size: 16px;
  background: #326949 url('/SiteFiles/img/svg/chevron-right-icon.svg') no-repeat right 10px center;
  color: #ffffff;
  padding: 4px 24px 4px 10px;
  border-radius: 0;
}
.product-category .product .btn:hover,
.product-category .product .btn:focus {
  background-color: #c5862d;
  color: #ffffff;
}
.product-category .category-seo {
  float: left;
  width: 91%;
  margin-top: 60px;
  background-color: #f4f4f4;
  color: #0b2d1d !important;
  overflow: hidden;
  padding: 20px;
  display: none;
}
.product-category .expand-seo {
  float: left;
  width: 8%;
  margin-top: 60px;
  background-color: #f4f4f4;
  color: #0b2d1d;
  line-height: 20px;
  padding: 20px 20px 16px;
  font-size: 11px !important;
  cursor: pointer;
  font-weight: bold;
}
.product-category .expand-seo .arrow {
  background: url(/sitefiles/img/sprite.png) -511px -148px no-repeat;
  width: 20px;
  height: 20px;
  margin: 0 auto;
  display: block;
  margin-top: 4px;
}
.product-category .expand-seo .arrow.open {
  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipV;
  -ms-filter: 'FlipV';
  margin-top: -4px;
}
.product-category .seo-bottom {
  display: block;
  background-color: #f4f4f4;
  width: 99%;
  height: 20px;
  float: left;
}
@media (max-width: 991px) {
  .product-category .expand-seo {
    padding: 20px 10px 16px;
  }
}
@media (max-width: 480px) {
  .product-category .expand-seo {
    padding: 20px 3px 16px;
  }
  .product-category .product a {
    font-size: 15px;
  }
  .product-category .product span {
    font-size: 14px;
  }
  .product-category .product .btn {
    font-size: 12px;
  }
}
.delivery h1 {
  display: block;
  margin-bottom: 35px;
}
.delivery .delivery-left {
  padding-left: 30px;
}
.delivery h2 {
  background: none;
  line-height: normal;
  color: #00421b;
  font-size: 1.5714em;
  padding: 0;
}
.delivery .map-keys {
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
}
.delivery .map-keys h3 {
  text-transform: none;
  font-size: 1.2857em;
  font-weight: normal;
  margin-bottom: 15px;
  font-family: Arial, sans-serif;
}
.delivery .map-keys .map-square {
  position: absolute;
  top: 0;
  left: 0;
  width: 21px;
  height: 21px;
  display: block;
}
.delivery .map-keys p.red {
  color: #ed2729;
  font-weight: bold;
}
.delivery .map-img {
  margin-bottom: 35px;
}
@media (max-width: 767px) {
  .delivery .delivery-left {
    padding-left: 0;
  }
}
.replacement-covers-page h1 {
  display: block;
}
.replacement-covers-page h5 {
  font-weight: bold;
  font-size: 1em;
  font-family: Arial, sans-serif;
  margin-bottom: 10px;
}
.newsletter h1 {
  display: block;
  z-index: 5;
  margin: 0;
  position: relative;
}
.newsletter .bottom {
  height: 26px;
  background: url(/SiteFiles/img/boxbtm.png) no-repeat;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
  width: 92%;
  position: relative;
  left: 1%;
  display: block;
}
.newsletter .newsletter-main {
  background: #bcbca9;
  padding: 15px;
  z-index: 1;
  margin-top: -10px;
  width: 92%;
  position: relative;
  left: 1%;
}
.newsletter .newsletter-main .InfoMessage,
.newsletter .newsletter-main .ErrorLabel {
  text-align: center;
  display: block;
  color: #c10d0d;
  font-size: 1em;
  font-weight: bold;
}
.newsletter .newsletter-main .ErrorMessage {
  display: block;
  color: #c10d0d;
  font-size: 1em;
  text-align: left;
  padding-bottom: 10px;
}
.newsletter .newsletter-main .required {
  display: block;
  text-align: right;
  color: #c10d0d;
  font-size: 1em;
  margin-bottom: 15px;
}
.newsletter .newsletter-main .newsletter-row {
  position: relative;
}
.newsletter .newsletter-main .newsletter-row .required-star {
  position: absolute;
  top: 17px;
  right: -10px;
  color: #c10d0d;
}
.newsletter .newsletter-main .newsletter-row .CaptchaTextBox {
  width: 18%;
}
.newsletter .newsletter-main .newsletter-row .captcha-code img {
  width: 32%;
  height: 46px;
  float: right;
}
.newsletter .newsletter-main input {
  background: #fff;
  color: #05461f;
  border-radius: 0;
  border: none;
  box-shadow: none;
  height: auto;
  padding: 15px;
  font-size: 1em;
  width: 50%;
  margin: 0 0 3px 0;
  display: block;
  float: left;
  line-height: normal;
}
.newsletter .newsletter-main label {
  background: #fff;
  color: #05461f;
  border-radius: 0;
  border: none;
  box-shadow: none;
  height: auto;
  padding: 15px;
  font-size: 1em;
  line-height: normal;
  width: 50%;
  margin: 0 0 3px 0;
  display: block;
  float: left;
  border-right: 3px solid #bcbca9;
  text-align: right;
}
.newsletter .newsletter-main input[type='submit'] {
  background: #53ae3d;
  float: none;
  width: auto;
  margin: 0;
  display: inline-block;
  padding: 15px 75px;
  color: #fff;
  font-size: 1.2em;
}
.newsletter .newsletter-main input[type='submit']:hover {
  background: #be7915;
}
@media (max-width: 991px) {
  .newsletter .bottom {
    width: 98%;
  }
  .newsletter .newsletter-main {
    width: 98%;
  }
}
@media (max-width: 767px) {
  .newsletter {
    margin-bottom: 15px;
  }
}
@media (max-width: 480px) {
  .newsletter .bottom {
    width: 100%;
    left: 0;
  }
  .newsletter .newsletter-main {
    margin-top: 0;
    width: 100%;
    left: 0;
  }
  .newsletter .newsletter-main input,
  .newsletter .newsletter-main label {
    float: none;
    width: 100%;
    border: none;
    text-align: center;
  }
  .newsletter-row .CaptchaTextBox {
    width: 100%;
  }
  .newsletter-row .CaptchaImage {
    width: 100% !important;
  }
}
.NewsletterSubscription .cms-recaptcha-wrap > div {
  width: 100% !important;
}
.ui-datepicker {
  z-index: 20 !important;
}
.contact-us h1 {
  display: block;
  z-index: 5;
  margin: 0;
  position: relative;
}
.contact-us .EditingFormControlNestedControl .CalendarIcon {
  position: absolute;
  top: 17px;
  right: -20px;
}
.contact-us .contact-top {
  background: #346348;
  padding: 25px 15px 35px 15px;
  z-index: 1;
  margin-top: -10px;
  width: 92%;
  position: relative;
  left: 1%;
  font-size: 1.0714em;
}
.contact-us .contact-top h5 {
  color: #dae6df;
  font-family: Arial, sans-serif;
  font-size: 1em;
}
.contact-us .contact-top a {
  color: #dae6df;
  text-decoration: underline;
}
.contact-us .contact-top a:hover {
  color: #be7915;
}
.contact-us .contact-top p {
  color: #dae6df;
}
.contact-us .contact-top ul {
  margin: 0 0 15px 0;
  padding: 0;
}
.contact-us .contact-top ul li {
  color: #dae6df;
  list-style: none;
}
.contact-us .contact-top .tel-no a {
  text-decoration: none;
}
.contact-us .green-bottom {
  height: 26px;
  background: url(/SiteFiles/img/greenboxbtm.png) no-repeat;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
  width: 92%;
  position: relative;
  left: 1%;
  display: block;
  z-index: 10;
}
.contact-us .bottom {
  height: 26px;
  background: url(/SiteFiles/img/boxbtm.png) no-repeat;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
  width: 92%;
  position: relative;
  left: 1%;
  display: block;
}
.contact-us .contact-main {
  background: #bcbca9;
  padding: 41px 15px 15px;
  z-index: 1;
  margin-top: -26px;
  width: 92%;
  position: relative;
  left: 1%;
}
.contact-us .contact-main .form-received {
  text-align: center;
  display: block;
  margin-bottom: 100px;
}
.contact-us .contact-main .ErrorLabel {
  text-align: center;
  display: block;
  color: #C10D0D;
  font-size: 1em;
  font-weight: bold;
}
.contact-us .contact-main .EditingFormErrorLabel {
  color: #C10D0D;
  font-size: 1em;
  text-align: left;
  padding-bottom: 0;
}
.contact-us .contact-main .required {
  display: block;
  text-align: right;
  color: #C10D0D;
  font-size: 1em;
  margin-bottom: 15px;
}
.contact-us .contact-main .contact-row {
  position: relative;
}
.contact-us .contact-main .contact-row .required-star {
  position: absolute;
  top: 17px;
  right: -10px;
  color: #C10D0D;
}
.contact-us .contact-main .contact-row .CaptchaTextBox {
  width: 18%;
}
.contact-us .contact-main .contact-row .captcha-code img {
  width: 32%;
  height: 46px;
  float: right;
}
.contact-us .contact-main select {
  background: #fff;
  color: #05461f;
  border-radius: 0;
  border: none;
  box-shadow: none;
  height: auto;
  padding: 14px;
  font-size: 1em;
  width: 50%;
  margin: 0 0 3px 0;
  display: block;
  float: left;
  line-height: normal;
}
.contact-us .contact-main .chzn-container {
  width: 50%!important;
  background: #fff;
  height: 47px;
}
.contact-us .contact-main .chzn-container .chzn-single {
  padding: 10px 15px;
  height: 47px;
}
.contact-us .contact-main .chzn-container-single .chzn-single div {
  top: 9px;
}
.contact-us .contact-main .chzn-container .chzn-results li {
  padding: 10px 15px;
}
.contact-us .contact-main input {
  background: #fff;
  color: #05461f;
  border-radius: 0;
  border: none;
  box-shadow: none;
  height: auto;
  padding: 15px;
  font-size: 1em;
  width: 50%;
  margin: 0 0 3px 0;
  display: block;
  float: left;
  line-height: normal;
}
.contact-us .contact-main .RadioButtonList {
  width: 50%;
  background: #fff;
  height: 46px;
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}
.contact-us .contact-main .RadioButtonList input[type=radio] {
  /*height: 15px;*/
  margin: 17px 0;
  width: 25%;
}
.contact-us .contact-main .RadioButtonList label {
  border-right: none;
  margin: 5px 0;
  padding: 0;
  text-align: left;
  line-height: 36px;
  width: 25%;
}
.contact-us .contact-main .EditingFormLabel {
  background: #fff;
  color: #05461f;
  border-radius: 0;
  border: none;
  box-shadow: none;
  height: auto;
  padding: 15px;
  font-size: 1em;
  line-height: normal;
  width: 50%;
  margin: 0 0 3px 0;
  display: block;
  float: left;
  border-right: 3px solid #bcbca9;
  text-align: right;
}
.contact-us .contact-main textarea {
  background: #fff;
  color: #05461f;
  border-radius: 0;
  border: none;
  box-shadow: none;
  height: 150px;
  padding: 15px;
  font-size: 1em;
  width: 50%;
  margin: 0 0 3px 0;
  display: block;
  float: left;
  line-height: normal;
}
.contact-us .contact-main .textarea-label {
  height: 150px;
}
.contact-us .contact-main label {
  background: #fff;
  color: #05461f;
  border-radius: 0;
  border: none;
  box-shadow: none;
  height: auto;
  padding: 15px;
  font-size: 1em;
  line-height: normal;
  width: 50%;
  margin: 0 0 3px 0;
  display: block;
  float: left;
  border-right: 3px solid #bcbca9;
  text-align: right;
}
.contact-us .contact-main input[type=submit] {
  background: #53ae3d;
  float: none;
  width: auto;
  margin: 0;
  display: inline-block;
  padding: 15px 75px;
  color: #fff;
  font-size: 1.2em;
}
.contact-us .contact-main input[type=submit]:hover {
  background: #be7915;
}
@media (max-width: 991px) {
  .contact-us .bottom,
  .contact-us .green-bottom {
    width: 98%;
  }
  .contact-us .contact-main,
  .contact-us .contact-top {
    width: 98%;
  }
}
@media (max-width: 767px) {
  .contact-us {
    margin-bottom: 15px;
  }
}
@media (max-width: 480px) {
  .contact-us .bottom,
  .contact-us .green-bottom {
    width: 100%;
    left: 0;
  }
  .contact-us .contact-main,
  .contact-us .contact-top {
    width: 100%;
    left: 0;
  }
  .contact-us .contact-top {
    margin-top: 0;
  }
  .contact-us .contact-main input,
  .contact-us .contact-main label,
  .contact-us .contact-main textarea,
  .contact-us .contact-main .chzn-container {
    float: none;
    width: 100%!important;
    border: none;
    text-align: center;
  }
  .chzn-container-single .chzn-single span {
    margin: 0 31px;
  }
  .contact-us .contact-main input[type="submit"] {
    padding: 15px;
    display: block;
    width: 100%;
  }
  .contact-us .contact-main select {
    float: none;
    width: 100%;
    text-align: center;
  }
  .contact-us .contact-main .textarea-label {
    height: auto;
  }
  .contact-row .CaptchaImage {
    width: 100%!important;
  }
  .contact-us .contact-main .EditingFormLabel {
    border-right: none;
    width: 100%;
  }
  .contact-us .contact-main .RadioButtonList {
    width: 100%;
  }
  .contact-us .contact-main .RadioButtonList label {
    text-align: center;
  }
}
.IE9 .contact-us .contact-main .RadioButtonList input[type="radio"] {
  margin: 0;
}
.error-page h1 {
  display: block;
  font-size: 1.5714em;
}
.error-page h2 {
  background: none;
  line-height: normal;
  padding: 0;
  color: #00421b;
  margin: 45% 0 10px 0;
}
.error-page a {
  text-transform: uppercase;
  font-weight: bold;
  font-family: 'Averia Sans Libre', sans-serif;
  font-size: 1.5714em;
}
@media (max-width: 991px) {
  .error-page h2 {
    margin-top: 20%;
  }
}
@media (max-width: 767px) {
  .error-page h2 {
    margin-top: 0;
  }
}
.search-results {
  margin-bottom: 35px;
}
.search-results .search-result {
  margin-bottom: 35px;
}
.search-results h1 {
  display: block;
  z-index: 5;
  margin: 0;
  position: relative;
}
.search-results h4 {
  text-transform: uppercase;
  font-size: 2em;
  margin-top: 5px;
}
.search-results h5 {
  font-family: Arial, sans-serif;
  font-size: 1.4285em;
  margin-bottom: 15px;
}
.search-results .bottom {
  height: 26px;
  background: url(/SiteFiles/img/boxbtm.png) no-repeat;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
  width: 92%;
  position: relative;
  left: 1%;
  display: block;
  margin-bottom: 35px;
}
.search-results .search-main {
  background: #bcbca9;
  padding: 35px 15px 25px;
  z-index: 1;
  margin-top: -10px;
  width: 92%;
  position: relative;
  left: 1%;
}
.search-results input {
  border-radius: 0;
  box-shadow: none;
  border: 1px solid #88836e;
  height: 47px;
  line-height: 40px;
}
.search-results input[type="submit"] {
  display: none;
}
.search-results .highlighted {
  background: #be7915;
  color: #fff;
}
.search-results .relevance-bar {
  border: 1px solid #be7915;
  color: #be7915;
  font-size: 2pt;
  height: 8px;
  line-height: 7px;
  width: 188px;
  display: block;
  margin-top: 15px;
}
.search-results .relevance-bar .relevance-fill {
  background-color: #be7915;
  height: 7px;
  line-height: 4px;
  -moz-transition: width 1s ease 0.2s;
  -o-transition: width 1s ease 0.2s;
  -webkit-transition: width 1s ease 0.2s;
  transition: width 1s ease 0.2s;
  width: 0;
}
@media (max-width: 991px) {
  .search-results .bottom {
    width: 98%;
  }
  .search-results .search-main {
    width: 98%;
  }
  .search-results h4 {
    font-size: 1.4em;
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  .search-results {
    margin-bottom: 15px;
  }
  .search-results h4 {
    margin-top: 0px;
    margin-bottom: 10px;
  }
}
@media (max-width: 480px) {
  .search-results .bottom {
    width: 100%;
    left: 0;
  }
  .search-results .search-main {
    margin-top: 0;
    width: 100%;
    left: 0;
  }
}
.growing-guides {
  padding-bottom: 20px;
}
.growing-guides .guides-top a {
  text-decoration: underline;
}
.growing-guides .guides-top a:hover {
  text-decoration: none;
}
.growing-guides .row {
  border-bottom: 1px solid #00421b;
  padding: 24px 12px 9px 0;
  margin: 0;
}
@media (min-width: 769px) {
  .growing-guides .row {
    padding-top: 33px;
    margin: 0 15px 0 0;
  }
}
.growing-guides .row:first-of-type {
  padding-top: 0;
}
.growing-guides .row:last-of-type {
  border: 0;
}
.growing-guides .row h2 {
  display: block;
  margin-bottom: 15px;
  color: #00421b;
  background: none;
  font-size: 21px;
  line-height: 28px;
  padding: 0;
}
@media (min-width: 769px) {
  .growing-guides .row h2 {
    font-size: 23px;
    line-height: 31px;
  }
}
.growing-guides h1 {
  margin-bottom: 35px;
  width: auto;
}
@media (max-width: 768px) {
  .growing-guides h1 {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 24px;
  }
}
.growing-guides h2 {
  background: none;
  padding: 0;
}
.growing-guides h3 {
  font-family: 'Averia Sans Libre', sans-serif;
  color: #61614b;
  font-size: 1.5714em;
  text-transform: uppercase;
  font-weight: normal;
  margin-bottom: 15px;
}
.growing-guides p {
  color: #000;
  font-size: 15px;
  margin-bottom: 15px;
}
.growing-guides .month-list,
.growing-guides .guide-options-list,
.growing-guides .pagination {
  list-style: none;
  padding: 0;
  font-weight: bold;
}
.growing-guides .month-list a,
.growing-guides .guide-options-list a {
  font-family: 'Averia Sans Libre', sans-serif;
  color: #000;
  text-decoration: none;
}
.growing-guides .month-list {
  margin: 0 0 7px;
}
@media (min-width: 769px) {
  .growing-guides .month-list {
    margin-bottom: 15px;
  }
}
.growing-guides .month-list li {
  display: inline-block;
  margin: 0 32px 16px 0;
}
.growing-guides .month-list li:last-of-type {
  margin-right: 0;
}
.growing-guides .month-list a {
  font-size: 12px;
  line-height: 16px;
  position: relative;
}
.growing-guides .month-list a::before {
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  position: absolute;
  content: '';
  background: url(/SiteFiles/img/svg/highlight-circle.svg) no-repeat center;
  background-size: 100% 100%;
  display: block;
  width: 280%;
  height: 280%;
  top: -105%;
  left: -90%;
  opacity: 0;
}
.growing-guides .month-list a:hover::before {
  opacity: 1;
}
.growing-guides .form-control-wrapper {
  margin: 0 0 24px;
}
.growing-guides .form-control-wrapper .chzn-single,
.growing-guides .form-control-wrapper select {
  border: 1px solid #00421b;
  height: 32px;
}
.growing-guides .form-control-wrapper select {
  border-radius: 0;
  color: #727272;
}
.growing-guides .form-control-wrapper .chzn-single span {
  line-height: 32px;
}
@media (min-width: 769px) {
  .growing-guides .form-control-wrapper {
    width: 175px;
  }
}
.growing-guides .guide-options-list-wrapper {
  padding: 0;
}
@media (min-width: 769px) {
  .growing-guides .guide-options-list-wrapper {
    padding: 0 20px 0 0;
  }
  .growing-guides .guide-options-list-wrapper:last-of-type {
    padding-right: 0;
  }
}
.growing-guides .guide-options-list-wrapper h3 {
  color: #00421b;
  font-size: 18px;
  line-height: 24px;
  border-bottom: 1px solid #00421b;
  padding: 0 0 13px;
  margin: 0 0 24px;
  width: 100%;
  display: flex;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
}
.growing-guides .guide-options-list-wrapper h3 .icon {
  -webkit-transition: transform 0.3s ease;
  -moz-transition: transform 0.3s ease;
  -ms-transition: transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  height: 7px;
  width: 14px;
}
@media (min-width: 769px) {
  .growing-guides .guide-options-list-wrapper h3 .icon {
    display: none;
  }
}
.growing-guides .guide-options-list-wrapper h3.opened .icon {
  -moz-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(180deg);
}
.growing-guides .guide-options-list-wrapper .guide-options-list {
  display: none;
  margin: 0 0 28px;
}
@media (min-width: 769px) {
  .growing-guides .guide-options-list-wrapper .guide-options-list {
    /** !important required to override jQuery toggle styles on non-mobile viewports */
    display: block !important;
    margin-bottom: 32px;
  }
}
.growing-guides .guide-options-list-wrapper .guide-options-list li {
  margin: 0 0 13px;
}
.growing-guides .guide-options-list-wrapper .guide-options-list a {
  font-size: 15px;
  line-height: 20px;
}
.growing-guides .guide-options-list-wrapper .guide-options-list a:hover {
  color: #be7915;
}
.growing-guides .blog-listings,
.growing-guides .growing-guides-listings {
  padding-top: 24px;
  border-top: 1px solid #00421b;
}
.growing-guides .growing-guides-listings {
  margin-top: 39px;
}
@media (min-width: 769px) {
  .growing-guides .growing-guides-listings {
    margin-top: 41px;
    padding-top: 24px;
  }
}
.growing-guides .blog-listings {
  margin-top: 24px;
}
.growing-guides .growing-guides-listing {
  display: grid;
  gap: 8px;
  border-bottom: 1px solid #9c9a7d;
  margin-bottom: 32px;
}
@media (min-width: 769px) {
  .growing-guides .growing-guides-listing {
    gap: 10px;
    grid-template-columns: 167px auto;
    margin-bottom: 24px;
  }
}
.growing-guides .growing-guides-listing__image img {
  width: 100%;
  max-width: auto;
}
.growing-guides .growing-guides-listing__top {
  display: grid;
  gap: 7px;
  margin-bottom: 17px;
}
@media (min-width: 769px) {
  .growing-guides .growing-guides-listing__top {
    gap: 10px;
    grid-template-columns: 55px auto;
    align-items: end;
    margin-bottom: 13px;
  }
}
.growing-guides .growing-guides-listing__date {
  background: #9c9a7d;
  height: 55px;
  width: 55px;
  padding: 6px;
  text-align: center;
  color: #ffffff;
  font-family: 'Averia Sans Libre', sans-serif;
}
.growing-guides .growing-guides-listing__month,
.growing-guides .growing-guides-listing__day,
.growing-guides .growing-guides-listing__year {
  display: block;
}
.growing-guides .growing-guides-listing__month {
  font-size: 16px;
  line-height: 15px;
}
.growing-guides .growing-guides-listing__day {
  font-size: 21px;
  line-height: 16px;
}
.growing-guides .growing-guides-listing__year {
  font-size: 12px;
  line-height: 16px;
}
.growing-guides .growing-guides-listing__title {
  border-bottom: 2px solid #9c9a7d;
}
.growing-guides .growing-guides-listing__title span {
  display: block;
  font-family: 'Averia Sans Libre', sans-serif;
  font-size: 13px;
  line-height: 17px;
  color: #346348;
  font-weight: bold;
}
.growing-guides .growing-guides-listing__title h1,
.growing-guides .growing-guides-listing__title h2 {
  color: #61614b;
}
.growing-guides .growing-guides-listing__title h1 {
  background: none;
  padding: 0;
  margin: 2px 0 5px;
  line-height: 27px;
  font-size: 20px;
}
.growing-guides .growing-guides-listing__title h2 {
  margin-bottom: 8px;
  font-size: 18px;
  line-height: 24px;
}
.growing-guides .growing-guides-listing__content {
  margin: 0 0 16px;
}
.growing-guides .growing-guides-listing__content p {
  font-size: 13px;
  line-height: 20px;
  margin: 0 0 7px;
}
.growing-guides .growing-guides-listing__content a {
  font-family: 'Averia Sans Libre', sans-serif;
  display: inline-block;
  font-size: 14px;
  line-height: 19px;
  color: #346348;
  font-weight: bold;
}
.growing-guides .growing-guides-listing__content a:hover {
  color: #be7915;
}
.growing-guides .growing-guides-detail {
  margin-top: 30px;
  color: #000;
}
.growing-guides .growing-guides-detail .growing-guides-listing__top {
  margin-bottom: 16px;
}
.growing-guides .growing-guides-detail__author {
  display: block;
  font-size: 13px;
  line-height: 20px;
  margin: 0 0 32px;
}
.growing-guides .growing-guides-detail__content h2,
.growing-guides .growing-guides-detail__related-title h2,
.growing-guides .growing-guides-detail__content h3,
.growing-guides .growing-guides-detail__related-title h3,
.growing-guides .growing-guides-detail__content h4,
.growing-guides .growing-guides-detail__related-title h4,
.growing-guides .growing-guides-detail__content h5,
.growing-guides .growing-guides-detail__related-title h5,
.growing-guides .growing-guides-detail__content li::before,
.growing-guides .growing-guides-detail__related-title li::before,
.growing-guides .growing-guides-detail__content figcaption,
.growing-guides .growing-guides-detail__related-title figcaption,
.growing-guides .growing-guides-detail__content strong,
.growing-guides .growing-guides-detail__related-title strong {
  color: #346348;
}
.growing-guides .growing-guides-detail__content h2,
.growing-guides .growing-guides-detail__related-title h2,
.growing-guides .growing-guides-detail__content h3,
.growing-guides .growing-guides-detail__related-title h3,
.growing-guides .growing-guides-detail__content h4,
.growing-guides .growing-guides-detail__related-title h4,
.growing-guides .growing-guides-detail__content h5,
.growing-guides .growing-guides-detail__related-title h5,
.growing-guides .growing-guides-detail__content p,
.growing-guides .growing-guides-detail__related-title p,
.growing-guides .growing-guides-detail__content ol,
.growing-guides .growing-guides-detail__related-title ol,
.growing-guides .growing-guides-detail__content ul,
.growing-guides .growing-guides-detail__related-title ul,
.growing-guides .growing-guides-detail__content figure,
.growing-guides .growing-guides-detail__related-title figure {
  margin: 0 0 20px;
}
.growing-guides .growing-guides-detail__content h2,
.growing-guides .growing-guides-detail__related-title h2 {
  font-size: 22px;
  line-height: 30px;
}
.growing-guides .growing-guides-detail__content {
  font-size: 15px;
  line-height: 22px;
  padding: 0 0 40px;
  border-bottom: 2px solid #9c9a7d;
  margin-bottom: 36px;
}
@media (min-width: 768px) {
  .growing-guides .growing-guides-detail__content {
    margin-bottom: 68px;
  }
}
.growing-guides .growing-guides-detail__content a {
  font-weight: bold;
  text-decoration: underline;
}
.growing-guides .growing-guides-detail__content a:hover {
  text-decoration: none;
}
.growing-guides .growing-guides-detail__content h3,
.growing-guides .growing-guides-detail__content h4,
.growing-guides .growing-guides-detail__content h5 {
  font-size: 16px;
  line-height: 21px;
}
.growing-guides .growing-guides-detail__content ol,
.growing-guides .growing-guides-detail__content ul {
  list-style: none;
}
.growing-guides .growing-guides-detail__content ol li,
.growing-guides .growing-guides-detail__content ul li {
  margin: 0 0 3px;
}
.growing-guides .growing-guides-detail__content ol {
  counter-reset: li;
  padding-left: 54px;
}
.growing-guides .growing-guides-detail__content ol li {
  counter-increment: li;
}
.growing-guides .growing-guides-detail__content ol li::before {
  content: counter(li) '.';
  margin: 0 0.6em 0 -1.5em;
}
.growing-guides .growing-guides-detail__content ul li::before {
  content: '\2022';
  margin: 0 12px 0 -8px;
  font-size: 20px;
}
.growing-guides .growing-guides-detail__content figure img {
  display: block;
  margin: 0 0 4px;
  max-width: 100%;
}
.growing-guides .growing-guides-detail__content figure figcaption {
  font-size: 12px;
  line-height: 22px;
}
.growing-guides .growing-guides-detail__content iframe {
  max-width: 100%;
}
.growing-guides .growing-guides-detail .download-area {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  background: #346348 url(/SiteFiles/img/wateringcan.png) 98% center no-repeat;
  padding: 15px 129px 15px 110px;
  position: relative;
  min-height: 129px;
}
.growing-guides .growing-guides-detail .download-area h2,
.growing-guides .growing-guides-detail .download-area p {
  color: #ffffff;
}
.growing-guides .growing-guides-detail .download-area h2 {
  font-size: 19px;
  line-height: 26px;
  margin: 0 0 4px;
}
.growing-guides .growing-guides-detail .download-area p {
  font-size: 15px;
  line-height: 22px;
  margin: 0 0 12px;
}
.growing-guides .growing-guides-detail .download-area .pdf {
  background: url(/SiteFiles/img/sprite.png) -315px 0 no-repeat;
  display: block;
  width: 71px;
  height: 93px;
  position: absolute;
  top: 15px;
  left: 15px;
}
.growing-guides .growing-guides-detail .download-area a {
  background-color: #be7915;
  color: #ffffff;
  display: inline-block;
  -moz-transition: background-color ease 0.3s;
  -o-transition: background-color ease 0.3s;
  -webkit-transition: background-color ease 0.3s;
  transition: background-color ease 0.3s;
  padding: 8px 12px;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.25px;
  text-decoration: none;
}
.growing-guides .growing-guides-detail .download-area a:hover {
  background-color: #e69520;
}
.growing-guides .growing-guides-detail .download-area a .dl-text {
  margin-right: 4px;
}
.growing-guides .growing-guides-detail .download-area a .dl-icon svg {
  display: block;
  height: 14px;
  width: 14px;
}
.growing-guides .growing-guides-detail__comments .heading {
  color: #ffffff;
  text-transform: uppercase;
  font-family: 'Averia Sans Libre', sans-serif;
  font-size: 19px;
  line-height: 26px;
}
.growing-guides .growing-guides-detail__comments .summary {
  background-color: #9c9a7d;
  padding: 11px 10px 9px;
  margin-bottom: 8px;
}
.growing-guides .growing-guides-detail__comments .summary img {
  margin-right: 10px;
}
.growing-guides .growing-guides-detail__comments .comments-list {
  list-style: none;
  padding: 0;
}
.growing-guides .growing-guides-detail__comments .comment {
  margin: 16px 0;
  padding: 0 0 16px;
  border-bottom: 1px solid #346348;
}
.growing-guides .growing-guides-detail__comments .comment .author,
.growing-guides .growing-guides-detail__comments .comment time {
  font-family: 'Averia Sans Libre', sans-serif;
  font-size: 14px;
  line-height: 21px;
}
.growing-guides .growing-guides-detail__comments .comment .author {
  text-transform: uppercase;
  color: #346348;
}
.growing-guides .growing-guides-detail__comments .comment time {
  color: #61614b;
}
.growing-guides .growing-guides-detail__comments .comment:last-of-type {
  padding: 0;
  border: 0;
}
.growing-guides .growing-guides-detail__comments .comment-form {
  background-color: #be7915;
  padding: 10px 16px 16px;
  margin-bottom: 48px;
}
.growing-guides .growing-guides-detail__comments .comment-form label {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}
.growing-guides .growing-guides-detail__comments .comment-form .heading {
  display: block;
  margin-bottom: 10px;
}
.growing-guides .growing-guides-detail__comments .comment-form .validation-error {
  margin: 3px 0 6px;
}
.growing-guides .growing-guides-detail__comments .comment-form .validation-error span {
  display: block;
  font-size: 11px;
  line-height: 13px;
}
.growing-guides .growing-guides-detail__comments .comment-form input,
.growing-guides .growing-guides-detail__comments .comment-form textarea {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  box-shadow: none;
  margin: 0;
}
.growing-guides .growing-guides-detail__comments .comment-form input[type='text'],
.growing-guides .growing-guides-detail__comments .comment-form textarea {
  border: 1px solid #d1d7d3;
  font-size: 12px;
  line-height: 20px;
  background-color: #ffffff;
  color: #61614b;
}
.growing-guides .growing-guides-detail__comments .comment-form input[type='text']::placeholder,
.growing-guides .growing-guides-detail__comments .comment-form textarea::placeholder {
  color: #61614b;
}
.growing-guides .growing-guides-detail__comments .comment-form input {
  padding: 0 8px;
  height: 32px;
}
.growing-guides .growing-guides-detail__comments .comment-form textarea {
  padding: 8px;
  height: 82px;
}
.growing-guides .growing-guides-detail__comments .comment-form input[type='submit'] {
  display: block;
  width: auto;
  background-color: #7d9c86;
  color: #ffffff;
  border: 0;
  -moz-transition: background-color ease 0.3s;
  -o-transition: background-color ease 0.3s;
  -webkit-transition: background-color ease 0.3s;
  transition: background-color ease 0.3s;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
}
.growing-guides .growing-guides-detail__comments .comment-form input[type='submit']:hover {
  background-color: #346348;
}
@media (min-width: 768px) {
  .growing-guides .growing-guides-detail__comments .comment-form {
    margin-bottom: 80px;
  }
  .growing-guides .growing-guides-detail__comments .comment-form .split-row {
    display: flex;
    gap: 16px;
  }
  .growing-guides .growing-guides-detail__comments .comment-form .split-row .input-wrapper {
    flex: 1;
  }
}
.growing-guides .growing-guides-detail__related .growing-guides-listings {
  margin-top: 0;
  padding-top: 0;
  border: 0;
}
@media (min-width: 768px) {
  .growing-guides .growing-guides-detail__related .growing-guides-listing__top {
    grid-template-columns: auto;
  }
  .growing-guides .growing-guides-detail__related .growing-guides-listing__date {
    display: none;
  }
}
@media (max-width: 768px) {
  .pagination-wrapper {
    margin: 72px 0 32px;
    display: grid;
    place-content: center;
  }
}
.pagination {
  border-radius: 0;
}
.pagination li span,
.pagination li a {
  border-radius: 0 !important;
  font-weight: 400;
  padding-left: 16px;
  padding-right: 16px;
}
.pagination li a {
  color: #00421b;
}
.pagination li span,
.pagination li span:hover {
  color: #6c757d;
  background-color: #ffffff;
}
.pagination li a:hover,
.pagination li .active,
.pagination li .active:hover {
  color: #ffffff;
  background-color: #00421b;
  border-color: #00421b;
}
.checkout {
  min-height: 400px;
}
.checkout h1 {
  display: block;
  z-index: 5;
  margin: 0;
  position: relative;
}
.checkout .tooltip-btn {
  display: inline-block;
  vertical-align: text-top;
  margin-left: 6px;
  width: 14px;
  height: 14px;
  background: url(/SiteFiles/img/svg/tooltip-icon.svg) no-repeat center;
  cursor: pointer;
}
.checkout .checkout-top {
  background: #346348;
  padding: 25px 15px 35px 15px;
  z-index: 1;
  margin-top: -10px;
  width: 92%;
  position: relative;
  left: 1%;
  font-size: 1.0714em;
}
.checkout .checkout-top p:nth-child(2) {
  font-weight: bold;
}
.checkout .checkout-top h5 {
  color: #dae6df;
  font-family: Arial, sans-serif;
  font-size: 1em;
}
.checkout .checkout-top a {
  color: #dae6df;
  text-decoration: underline;
}
.checkout .checkout-top a:hover {
  color: #be7915;
}
.checkout .checkout-top p {
  color: #dae6df;
}
.checkout .checkout-top .empty {
  text-align: center;
}
.checkout .checkout-top ul {
  margin: 0 0 15px 0;
  padding: 0;
}
.checkout .checkout-top ul li {
  color: #dae6df;
  list-style: none;
}
.checkout .checkout-top .cart-item:first-of-type .cart-title {
  display: block;
}
.checkout .checkout-top .cart-item {
  border-bottom: 2px solid #346348;
  display: table;
  width: 100%;
}
.checkout .checkout-top .cart-item .cart-title {
  background: #203c27;
  color: #fff;
  text-align: center;
  padding: 15px 10px;
  border-bottom: 2px solid #346348;
  display: none;
}
.checkout .checkout-top .cart-item .cart-title h3 {
  color: #fff;
  font-family: Arial, sans-serif;
  font-size: 1.067em;
  font-weight: normal;
}
.checkout .checkout-top .cart-item .product-details {
  padding: 10px;
}
.checkout .checkout-top .cart-item .product-details p {
  color: #00421b;
  margin-bottom: 5px;
}
.checkout .checkout-top .cart-item .product-details h4 {
  color: #00421b;
  font-family: Arial, sans-serif;
  font-size: 1.267em;
  font-weight: bold;
  margin-bottom: 10px;
}
.checkout .checkout-top .cart-item .product-details h4 a {
  color: #00421b;
  text-decoration: none;
}
.checkout .checkout-top .cart-item .product-details h4 a:hover {
  color: #be7915;
}
.checkout .checkout-top .cart-item .col1 {
  width: 192px;
  display: block;
  float: left;
  border-right: 2px solid #346348;
  background: #fff;
  vertical-align: top;
}
.checkout .checkout-top .cart-item .col2 {
  width: 105px;
  display: block;
  float: left;
  border-right: 2px solid #346348;
  background: #fff;
  vertical-align: top;
}
.checkout .checkout-top .cart-item .col3 {
  width: 92px;
  display: block;
  float: left;
  border-right: 2px solid #346348;
  background: #fff;
  vertical-align: top;
}
.checkout .checkout-top .cart-item .col3 .quantity-box {
  height: 40px;
  position: relative;
  top: 50%;
  margin: 0;
}
.checkout .checkout-top .cart-item .col3 .quantity-box input {
  width: 46px;
  border: 1px solid #8a968d;
  border-radius: 0;
  box-shadow: none;
  text-align: center;
  float: right;
  height: 39px;
}
.checkout .checkout-top .cart-item .col3 .quantity-box .plus-minus {
  width: 20px;
  margin-right: 3px;
  float: left;
}
.checkout .checkout-top .cart-item .col3 .quantity-box .plus-minus span {
  display: block;
  font-size: 18px;
  padding: 0;
  color: #fff;
  text-align: center;
  line-height: normal;
  width: 20px;
  height: 17px;
  cursor: pointer;
}
.checkout .checkout-top .cart-item .col3 .quantity-box .plus-minus .plus-btn {
  background: url(/SiteFiles/img/sprite.png) -173px -115px no-repeat;
  margin-bottom: 3px;
  display: block;
  width: 19px;
  height: 17px;
}
.checkout .checkout-top .cart-item .col3 .quantity-box .plus-minus .minus-btn {
  background: url(/SiteFiles/img/sprite.png) -173px -135px no-repeat;
  display: block;
  width: 19px;
  height: 17px;
}
.checkout .checkout-top .cart-item .col4 {
  width: 105px;
  display: block;
  float: left;
  border-right: 2px solid #346348;
  text-align: center;
  background: #fff;
  vertical-align: top;
}
.checkout .checkout-top .cart-item .col4 .product-details h4 {
  height: 22px;
  position: relative;
  top: 50%;
  margin: 0;
}
.checkout .checkout-top .cart-item .col5 {
  width: 112px;
  display: block;
  float: left;
  text-align: center;
  background: #fff;
  vertical-align: top;
}
.checkout .checkout-top .cart-item .col5 .product-details h4 {
  font-size: 2em;
  height: 36px;
  position: relative;
  top: 50%;
  margin: 0;
}
.checkout .cart-totals {
  display: block;
  width: 100%;
}
.checkout .cart-totals p {
  color: #00421b;
  margin-bottom: 5px;
}
.checkout .cart-totals h4 {
  color: #c10d0d;
  font-family: Arial, sans-serif;
  font-size: 1.067em;
  font-weight: bold;
}
.checkout .cart-totals h4 span {
  display: block;
  font-size: 0.6em;
}
.checkout .cart-totals .col1 {
  width: 494px;
  display: block;
  float: left;
  border-right: 2px solid #346348;
  background: #fff;
  vertical-align: top;
  text-align: right;
  padding: 10px;
}
.checkout .cart-totals .col2 {
  background: #ffffff;
  border-right: none;
  display: block;
  float: left;
  text-align: center;
  vertical-align: top;
  width: 112px;
  padding: 10px 2px;
}
.checkout .cart-totals .col3 {
  display: none;
}
.checkout .green-bottom {
  height: 26px;
  background: url(/SiteFiles/img/greenboxbtm.png) no-repeat;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
  width: 92%;
  position: relative;
  left: 1%;
  display: block;
  z-index: 10;
}
.checkout .bottom {
  height: 26px;
  background: url(/SiteFiles/img/boxbtm.png) no-repeat;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
  width: 92%;
  position: relative;
  left: 1%;
  display: block;
}
.checkout .checkout-main {
  background: #bcbca9;
  padding: 41px 30px 15px;
  z-index: 1;
  margin-top: -26px;
  width: 92%;
  position: relative;
  left: 1%;
}
.checkout .checkout-main h3 {
  text-transform: uppercase;
  width: 60%;
  float: left;
  font-weight: normal;
  font-size: 1.4285em;
}
.checkout .checkout-main .checkout-row {
  position: relative;
}
.checkout .checkout-main .checkout__optional span {
  font-style: italic;
}
.checkout .checkout-main select {
  background: #fff;
  color: #05461f;
  border-radius: 0;
  border: none;
  box-shadow: none;
  height: auto;
  padding: 14px;
  font-size: 1em;
  width: 100%;
  margin: 0 0 3px 0;
  display: block;
  line-height: normal;
  border-radius: 3px;
}
.checkout .checkout-main .chzn-container {
  width: 100% !important;
  background: #fff;
  height: 47px;
  border-radius: 3px;
}
.checkout .checkout-main .chzn-container .chzn-single {
  padding: 10px 15px;
  height: 47px;
  border-radius: 3px;
}
.checkout .checkout-main .chzn-container .chzn-results li {
  padding: 10px 15px;
}
.checkout .checkout-main input {
  background: #fff;
  color: #05461f;
  border-radius: 0;
  border: none;
  box-shadow: none;
  height: auto;
  padding: 15px;
  font-size: 1em;
  width: 100%;
  margin: 0 0 3px 0;
  display: block;
  line-height: normal;
  border-radius: 3px;
}
.checkout .checkout-main textarea {
  background: #fff;
  color: #05461f;
  border-radius: 0;
  border: none;
  box-shadow: none;
  height: 150px;
  padding: 15px;
  font-size: 1em;
  width: 100%;
  margin: 0 0 3px 0;
  display: block;
  line-height: normal;
  border-radius: 3px;
}
.checkout .checkout-main .textarea-label {
  height: 150px;
}
.checkout .checkout-main label {
  background: transparent;
  color: #05461f;
  border-radius: 0;
  border: none;
  box-shadow: none;
  height: auto;
  padding: 15px 0 1px;
  font-size: 1em;
  line-height: normal;
  width: auto;
  margin: 0 0 3px 0;
  display: inline-block;
  text-align: left;
}
.checkout .checkout-main input[type='submit'] {
  background: #53ae3d;
  float: none;
  width: auto;
  margin: 0;
  display: inline-block;
  padding: 15px 75px;
  color: #fff;
  font-size: 1.2em;
}
.checkout .checkout-main input[type='submit']:hover {
  background: #be7915;
}
.checkout .checkout-step {
  margin: 0 auto 35px;
  max-width: 304px;
}
.checkout .checkout-step .checkout-step__top {
  text-align: center;
}
.checkout .checkout-step .step-head {
  text-align: center;
}
.checkout .checkout-step .step-head h3 {
  float: none;
  width: 100%;
  padding-bottom: 5px;
}
.checkout .checkout-step .hidden-validation {
  display: none;
}
.checkout .checkout-step .checkout-error-small {
  float: left;
  margin-bottom: 7px;
  color: #e61d1d;
}
.checkout .checkout-step .checkout-error-postcode {
  float: left;
  color: #e61d1d;
}
.checkout .checkout-step .checkout-error {
  margin-top: 20px;
  color: #e61d1d;
}
.checkout .checkout-step .billing-question {
  background: #fff;
  margin-bottom: 3px;
  padding: 12px 152px 13px 15px;
  position: relative;
  min-height: 55px;
}
.checkout .checkout-step .billing-question h3 {
  float: none;
  width: 100%;
  margin-top: 5px;
}
.checkout .checkout-step .billing-question .billing-yes {
  position: absolute;
  top: 12px;
  right: 82px;
  width: 57px;
  display: block;
  text-align: center;
  background: #53ae3d;
  color: #fff;
  font-family: 'Averia Sans Libre', sans-serif;
  font-size: 1.1428em;
  padding: 3px 0;
  cursor: pointer;
  border: 2px solid #53ae3d;
}
.checkout .checkout-step .billing-question .billing-yes:hover {
  background: #be7915;
  border-color: #be7915;
}
.checkout .checkout-step .billing-question .billing-yes.activeQuestion,
.checkout .checkout-step .billing-question .billing-no.activeQuestion {
  border-color: #05461f;
}
.checkout .checkout-step .billing-question .billing-no {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 57px;
  display: block;
  text-align: center;
  background: #e61d1d;
  color: #fff;
  font-family: 'Averia Sans Libre', sans-serif;
  font-size: 1.1428em;
  padding: 3px 0;
  cursor: pointer;
  border: 2px solid #e61d1d;
}
.checkout .checkout-step .billing-question .billing-no:hover {
  background: #be7915;
  border-color: #be7915;
}
.checkout .checkout-step .hidden-billing {
  display: none;
}
.checkout .checkout-step .chk-btn {
  position: relative;
  padding-left: 25px;
  color: #00421b;
}
.checkout .checkout-step .chk-btn:before {
  content: '';
  position: absolute;
  top: -2px;
  left: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #05461f;
  border-radius: 50%;
}
.checkout .checkout-step .chk-btn.active:after {
  content: '';
  position: absolute;
  left: 5px;
  top: 3px;
  width: 10px;
  height: 10px;
  background-color: #05461f;
  border-radius: 50%;
  opacity: 1;
}
.checkout .checkout-step .checkout__billing input[type='checkbox'] {
  display: none;
}
.checkout .checkout-step .checkout__billing input[type='checkbox']:checked + label:after {
  opacity: 1;
}
.checkout .checkout-step .checkout__billing label {
  font-size: 14px;
  line-height: 16px;
  position: relative;
  padding-top: 0;
  padding-left: 30px;
  cursor: pointer;
}
.checkout .checkout-step .checkout__billing label:before {
  content: '';
  position: absolute;
  top: -2px;
  left: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #05461f;
  border-radius: 50%;
}
.checkout .checkout-step .checkout__billing label:after {
  content: '';
  position: absolute;
  left: 5px;
  top: 3px;
  width: 10px;
  height: 10px;
  background-color: #05461f;
  border-radius: 50%;
  opacity: 0;
}
.checkout .payment-button {
  position: relative;
  display: inline-block;
}
.checkout .payment-button .tick {
  background: url(/SiteFiles/img/sprite.png) -119px -119px no-repeat;
  width: 26px;
  height: 25px;
  display: block;
  position: absolute;
  top: 12px;
  right: 10px;
}
.checkout-modal {
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-height: 100%;
  padding: 80px 25px;
  background: rgba(0, 0, 0, 0.75);
  opacity: 0;
  pointer-events: none;
  transition: opacity 250ms ease-out;
}
.checkout-modal.open {
  opacity: 1;
  pointer-events: all;
}
.checkout-modal .checkout-modal-inner {
  position: relative;
  background: #ffffff;
  padding: 4px 10px 30px;
  width: 100%;
  max-width: 710px;
  overflow-y: auto;
  overflow-x: hidden;
}
.checkout-modal .close-btn {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  height: 62px;
  width: 62px;
  background-color: #336348;
  cursor: pointer;
}
.checkout-modal .close-btn:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2px;
  height: 35px;
  background-color: #ffffff;
  transform: translate(-50%, -50%) rotate(-45deg);
}
.checkout-modal .close-btn:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2px;
  height: 35px;
  background-color: #ffffff;
  transform: translate(-50%, -50%) rotate(45deg);
}
.checkout-modal .close-btn:hover {
  background-color: #be7915;
}
.js-price-panel-fixed {
  position: fixed;
  z-index: 9998;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 612px;
  padding-bottom: 102px;
  background-color: #ffffff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity 250ms ease-out;
  pointer-events: none;
  min-height: unset !important;
  margin: unset !important;
}
.js-price-panel-fixed.visible {
  opacity: 1;
  pointer-events: all;
}
@media (max-width: 1097px) {
  .js-price-panel-fixed {
    padding-bottom: 124px;
  }
}
@media (max-width: 1024px) {
  .js-price-panel-fixed {
    padding-bottom: unset;
  }
}
@media (max-width: 1010px) {
  .checkout .checkout-top .cart-item .col1 {
    width: 106px;
  }
  .checkout .checkout-top .cart-item .col2 {
    width: 79px;
  }
  .checkout .checkout-top .cart-item .col3 {
    width: 63px;
  }
  .checkout .checkout-top .cart-item .col4 {
    width: 82px;
  }
  .checkout .checkout-top .cart-item .col5 {
    width: 81px;
  }
  .checkout .cart-totals .col1 {
    width: 330px;
  }
  .checkout .cart-totals .col2 {
    width: 81px;
  }
}
@media (max-width: 991px) {
  .checkout .bottom,
  .checkout .green-bottom {
    width: 98%;
  }
  .checkout .checkout-main,
  .checkout .checkout-top {
    width: 98%;
  }
  .cart-item,
  .cart-totals {
    font-size: 0.7em;
  }
  .checkout .checkout-top .cart-item .col3 .product-details {
    padding: 10px 5px;
  }
  .checkout .checkout-top .cart-item .col3 .quantity-box input {
    width: 25px;
  }
}
@media (max-width: 767px) {
  .checkout .checkout-top .cart-item .product-details h4,
  .checkout .checkout-top .cart-item .col1,
  .checkout .checkout-top .cart-item .col2,
  .checkout .checkout-top .cart-item .col3,
  .checkout .checkout-top .cart-item .col4,
  .checkout .checkout-top .cart-item .col5 {
    height: auto !important;
  }
  .checkout {
    margin-bottom: 15px;
  }
  .checkout .checkout-top .cart-item .cart-title {
    display: block !important;
  }
  .checkout .cart-item .col1,
  .checkout .cart-item .col2,
  .checkout .cart-item .col3,
  .checkout .cart-item .col4,
  .checkout .cart-item .col5 {
    width: 100% !important;
    display: block !important;
    float: none !important;
    text-align: center;
  }
  .checkout .product-details {
    height: auto !important;
  }
  .checkout .checkout-top .cart-item .col5 .product-details h4,
  .checkout .checkout-top .cart-item .col4 .product-details h4 {
    position: static;
    margin: 0;
  }
  .checkout .checkout-top .cart-item .col3 .quantity-box {
    top: auto;
    left: auto;
    margin: 0;
    display: inline-block;
    width: auto;
  }
  .checkout .checkout-top .cart-item .col3 .quantity-box input {
    width: 50px;
  }
  .cart-item,
  .cart-totals {
    font-size: 0.8em;
  }
  .checkout .cart-totals .col1 {
    width: 50%;
    border: none;
  }
  .checkout .cart-totals .col2 {
    width: 50%;
    text-align: left;
    padding: 10px;
    border: none;
  }
  .checkout .cart-totals .col3 {
    display: none;
  }
  .checkout .checkout-top .cart-item .col5 {
    border-bottom: 5px solid #be7915;
  }
  .js-price-panel-fixed {
    height: 83px;
  }
}
@media (max-width: 480px) {
  .checkout .bottom,
  .checkout .green-bottom {
    width: 100%;
    left: 0;
  }
  .checkout .checkout-main,
  .checkout .checkout-top {
    width: 100%;
    left: 0;
  }
  .checkout .checkout-top {
    margin-top: 0;
  }
  .checkout .checkout-main input,
  .checkout .checkout-main label,
  .checkout .checkout-main textarea,
  .checkout .checkout-main .chzn-container {
    float: none;
    width: 100% !important;
    border: none;
    text-align: center;
  }
  .chzn-container-single .chzn-single span {
    margin: 0 31px;
  }
  .checkout .checkout-main input[type='submit'] {
    padding: 15px 43px 15px 15px;
    display: block;
    width: 100%;
  }
  .checkout .checkout-main select {
    float: none;
    width: 100%;
  }
  .checkout .checkout-main .textarea-label {
    height: auto;
  }
  .checkout .checkout-main h3 {
    width: 100%;
    margin-bottom: 10px;
    float: none;
  }
  .checkout .checkout-main .required {
    width: 100%;
    margin-bottom: 10px;
    float: none;
    text-align: left;
  }
  .checkout .checkout-step .billing-question .billing-yes {
    position: static;
    float: left;
  }
  .checkout .checkout-step .billing-question .billing-no {
    position: static;
    float: right;
  }
  .checkout .checkout-step .billing-question {
    padding-right: 15px;
  }
  .checkout-modal {
    padding: 25px;
  }
}
.polytunnel-price {
  display: none;
  position: absolute;
  top: 50px;
  width: 193px;
}
@media (max-width: 767px) {
  .polytunnel-price {
    display: block;
    position: fixed;
    width: 100%;
    left: 0;
    top: auto;
    bottom: 0;
    z-index: 1001;
  }
}
.polytunnel-price .polytunnel-price-top {
  background: url(/SiteFiles/img/polytunnel-price-top.png) no-repeat;
  display: block;
  width: 193px;
  height: 23px;
}
@media (max-width: 767px) {
  .polytunnel-price .polytunnel-price-top {
    display: none;
  }
}
.polytunnel-price .polytunnel-price-arrow {
  background: url(/SiteFiles/img/polytunnel-price-arrow.png) no-repeat;
  display: block;
  width: 27px;
  height: 29px;
  position: absolute;
  top: 23px;
  right: -27px;
}
@media (max-width: 767px) {
  .polytunnel-price .polytunnel-price-arrow {
    display: none;
  }
}
.polytunnel-price .polytunnel-price-inner {
  padding: 15px;
  background: #bf1121;
}
@media (max-width: 991px) {
  .polytunnel-price .polytunnel-price-inner {
    display: none;
  }
}
.polytunnel-price .polytunnel-price-inner h2 {
  background: none;
  line-height: normal;
  padding: 0;
  color: #fff;
  font-size: 1.4085em;
  text-transform: none;
  filter: none;
  -ms-filter: none;
}
@media (max-width: 767px) {
  .polytunnel-price .polytunnel-price-inner h2 {
    flex: 1 0 100%;
  }
}
.polytunnel-price .polytunnel-price-inner h4 {
  font-family: Arial, sans-serif;
  font-size: 1.5714em;
  color: #fff;
  margin-bottom: 5px;
}
.polytunnel-price .polytunnel-price-inner h4 span {
  font-size: 0.6863em;
}
@media (max-width: 767px) {
  .polytunnel-price .polytunnel-price-inner h4 {
    display: flex;
    flex-direction: column;
    flex: 0 0 20%;
  }
}
.polytunnel-price .polytunnel-price-inner h3 {
  font-family: Arial, sans-serif;
  font-size: 2.1428em;
  color: #fff;
}
.polytunnel-price .polytunnel-price-inner h3 span {
  font-size: 0.5063em;
}
@media (max-width: 767px) {
  .polytunnel-price .polytunnel-price-inner h3 {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
  }
}
.polytunnel-price .polytunnel-price-inner p {
  display: block;
  text-align: right;
  color: #fff;
}
@media (max-width: 767px) {
  .polytunnel-price .polytunnel-price-inner p {
    flex: 1 0 auto;
    text-align: left;
    margin-bottom: 3px;
    padding-left: 5px;
  }
}
.polytunnel-price .polytunnel-price-inner input[type='submit'] {
  background: #307a1a;
  border: medium none;
  border-radius: 0;
  box-shadow: none;
  color: #ffffff;
  display: block;
  font-family: 'Averia Sans Libre', sans-serif;
  line-height: normal;
  padding: 7px 15px 25px;
  text-align: center;
}
.polytunnel-price .polytunnel-price-inner input[type='submit']:hover {
  background: #be7915;
}
@media (max-width: 767px) {
  .polytunnel-price .polytunnel-price-inner input[type='submit'] {
    flex: 0 0 30%;
  }
}
.CartItemSelector {
  display: none;
}
.product-details .CartItemSelector {
  display: none;
}
.product-details h1 {
  display: block;
  z-index: 5;
  margin: 0;
  position: relative;
}
.product-details .owl-carousel {
  margin-bottom: 15px;
}
.product-details .owl-carousel .owl-wrapper-outer {
  background: #d1d7d3;
}
.product-details .owl-carousel .owl-item {
  padding: 15px 15px 10px;
}
.product-details .item-image,
.product-details .item-text {
  float: none;
  width: 100%;
  text-align: center;
  margin: 0;
}
@media (min-width: 500px) {
  .product-details .item-image {
    min-height: 210px;
  }
}
.product-details .owl-theme .owl-buttons {
  bottom: 0;
  height: 41px;
  left: 0;
  position: absolute;
  right: 0;
  top: auto;
  width: 100%;
  padding: 0 35px;
}
.product-details .item-text {
  margin-top: 10px;
}
.product-details .item-text h3 {
  color: #346348;
  font-size: 1.2857em;
  font-weight: normal;
}
.product-details .owl-theme .owl-controls .owl-buttons .owl-prev {
  top: auto;
  bottom: 12px;
  left: 15px;
  width: 12px;
  height: 16px;
  background-position: -3px -157px;
}
.product-details .owl-theme .owl-controls .owl-buttons .owl-next {
  top: auto;
  bottom: 12px;
  right: 15px;
  width: 12px;
  height: 16px;
  background-position: -24px -157px;
}
.product-details .product-details-top {
  background: #346348;
  padding: 35px 15px 15px;
  z-index: 1;
  margin-top: -10px;
  width: 92%;
  position: relative;
  left: 1%;
  font-size: 1.0714em;
}
.product-details .product-details-top h5 {
  color: #dae6df;
  font-family: Arial, sans-serif;
  font-size: 1em;
}
.product-details .product-details-top a {
  color: #dae6df;
  text-decoration: underline;
}
.product-details .product-details-top a:hover {
  color: #be7915;
}
.product-details .product-details-top p {
  color: #dae6df;
}
.product-details .product-details-top ul {
  margin: 0 0 15px 0;
  padding: 0;
}
.product-details .product-details-top ul li {
  color: #dae6df;
  list-style: none;
}
.product-details .product-details-top .product-description {
  color: #dae6df !important;
}
.product-details .product-details-top .product-description ul {
  margin: 0 0 15px 0;
  padding-left: 40px;
}
.product-details .product-details-top .product-description ul li {
  list-style: disc;
}
.product-details .green-bottom {
  height: 26px;
  background: url(/SiteFiles/img/greenboxbtm.png) no-repeat;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
  width: 92%;
  position: relative;
  left: 1%;
  display: block;
  z-index: 10;
}
.product-details .bottom {
  height: 26px;
  background: url(/SiteFiles/img/boxbtm.png) no-repeat;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
  width: 92%;
  position: relative;
  left: 1%;
  display: block;
}
.product-details .product-details-main {
  background: #bcbca9;
  padding: 41px 15px 15px;
  z-index: 1;
  margin-top: -26px;
  width: 92%;
  position: relative;
  left: 1%;
  z-index: 1000;
}
.product-details .product-details-main .control-label {
  color: #346348;
  text-transform: uppercase;
  font-weight: normal;
  margin-bottom: 15px;
}
.product-details .product-details-main .select-length,
.product-details .product-details-main .editing-form-value-cell {
  /* Hide radio button (the round disc)
                we will use just the label to create pushbutton effect */
  /*
                Change the look'n'feel of labels (which are adjacent to radiobuttons).
                Add some margin, padding to label
            */
  /*
                    Change background color for label next to checked radio button
                    to make it look like highlighted button
                */
}
.product-details .product-details-main .select-length input[type='radio'],
.product-details .product-details-main .editing-form-value-cell input[type='radio'] {
  display: none;
  margin: 10px;
}
.product-details .product-details-main .select-length h3,
.product-details .product-details-main .editing-form-value-cell h3 {
  color: #00421b;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 5px;
}
.product-details .product-details-main .select-length input[type='radio'] + label,
.product-details .product-details-main .editing-form-value-cell input[type='radio'] + label {
  display: inline-block;
  margin: 2px;
  padding: 15px 0 0;
  width: 100px;
  height: 51px;
  background: none;
  text-align: center;
  color: #203c27;
  font-size: 1em;
  font-family: Arial, sans-serif;
  cursor: pointer;
}
.product-details .product-details-main .select-length input[type='radio'] + label:hover,
.product-details .product-details-main .editing-form-value-cell input[type='radio'] + label:hover {
  background: url(/SiteFiles/img/radio-hover.png) no-repeat;
  background-size: cover;
  color: #fff;
}
.product-details .product-details-main .select-length input[type='radio']:checked + label,
.product-details .product-details-main .editing-form-value-cell input[type='radio']:checked + label {
  background: url(/SiteFiles/img/radio-select.png) no-repeat;
  background-size: cover;
  color: #fff;
}
.product-details .product-details-main .select-length .size-dropdown,
.product-details .product-details-main .editing-form-value-cell .size-dropdown {
  background: #8a886d;
  color: #ffffff;
  border-radius: 0;
  border: none;
  box-shadow: none;
  height: 35px;
}
.product-details .product-details-main .select-length .size-dropdown option,
.product-details .product-details-main .editing-form-value-cell .size-dropdown option {
  background-color: #fff;
  color: #00421b;
}
.product-details .product-details-main .select-length .chzn-container,
.product-details .product-details-main .editing-form-value-cell .chzn-container {
  position: relative;
}
.product-details .product-details-main .select-length .chzn-container:before,
.product-details .product-details-main .editing-form-value-cell .chzn-container:before {
  content: '';
  width: 15px;
  height: 100%;
  display: block;
  background: #8a886d;
  position: absolute;
  right: 100%;
  top: 0;
}
.product-details .product-details-main .select-length .chzn-container:after,
.product-details .product-details-main .editing-form-value-cell .chzn-container:after {
  content: '';
  width: 15px;
  height: 100%;
  display: block;
  background: #8a886d;
  position: absolute;
  left: 100%;
  top: 0;
}
.product-details .product-details-main .select-length .chzn-container-single .chzn-single,
.product-details .product-details-main .editing-form-value-cell .chzn-container-single .chzn-single {
  background: #8a886d;
  color: #ffffff;
  border: none;
  padding: 0 6px 0 0;
  box-shadow: none;
  height: 35px;
}
.product-details .product-details-main .select-length .chzn-container-single .chzn-single span,
.product-details .product-details-main .editing-form-value-cell .chzn-container-single .chzn-single span {
  color: #ffffff;
  margin-right: 0;
  margin-left: 31px;
  padding-left: 15px;
  line-height: 35px;
  font-size: 15px;
}
.product-details .product-details-main .select-length .chzn-container-single .chzn-single div,
.product-details .product-details-main .editing-form-value-cell .chzn-container-single .chzn-single div {
  right: auto;
  left: 0;
  background: #6c6a4c;
}
.product-details .product-details-main .select-length .chzn-container-single .chzn-single div b,
.product-details .product-details-main .editing-form-value-cell .chzn-container-single .chzn-single div b {
  background: url('/SiteFiles/img/dropdown-arrows.png') no-repeat -19px 8px;
}
.product-details .product-details-main .select-length .chzn-container-single .chzn-single.chzn-default div b,
.product-details .product-details-main .editing-form-value-cell .chzn-container-single .chzn-single.chzn-default div b {
  background: url('/SiteFiles/img/dropdown-arrows.png') no-repeat 6px 8px;
}
.product-details .product-details-main .select-length .chzn-container-single .chzn-drop,
.product-details .product-details-main .editing-form-value-cell .chzn-container-single .chzn-drop {
  box-shadow: none;
  border: none;
  border-radius: 0;
  max-width: 200px;
}
.product-details .product-details-main .select-length .chzn-container .chzn-results li,
.product-details .product-details-main .editing-form-value-cell .chzn-container .chzn-results li {
  padding: 2px 15px;
  color: #00421b;
  font-size: 14px;
}
.product-details .product-details-main .select-length .chzn-container .chzn-results li.highlighted,
.product-details .product-details-main .editing-form-value-cell .chzn-container .chzn-results li.highlighted {
  background: #00421b;
  color: #fff;
}
.product-details .product-details-main .select-length .chzn-container .chzn-results li.text-muted,
.product-details .product-details-main .editing-form-value-cell .chzn-container .chzn-results li.text-muted {
  opacity: 0.3;
  pointer-events: none;
}
.product-details .product-details-main .select-length .chzn-container .chzn-results li.text-muted:first-child,
.product-details .product-details-main .editing-form-value-cell .chzn-container .chzn-results li.text-muted:first-child {
  opacity: 1;
  pointer-events: initial;
}
.product-details .specifications {
  padding: 25px 45px 25px 25px;
  position: relative;
  margin-right: -25px;
}
.product-details .specifications h3 {
  text-transform: uppercase;
  font-size: 1.4285em;
  font-weight: normal;
  margin-bottom: 15px;
}
.product-details .specifications ul li {
  font-family: 'Averia Sans Libre', sans-serif;
  font-size: 1em;
  color: #00421b;
  line-height: 1.7em;
}
.product-details .specifications ul li p {
  margin: 0;
}
.product-details .specifications .pointer {
  background: url(/SiteFiles/img/sprite.png) -48px -152px no-repeat;
  width: 42px;
  height: 56px;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}
.product-details .specifications .angled-spec {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #b4bba9;
  -moz-transform: rotate(2deg);
  -webkit-transform: rotate(2deg);
  -ms-transform: rotate(2deg);
  -o-transform: rotate(2deg);
  transform: rotate(2deg);
  box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.25);
  z-index: -1;
}
.product-details h2.subtitle.fancy {
  background: none;
  color: #00421b;
  margin: 5px 0;
  overflow: hidden;
  text-align: center;
  display: block;
  width: 92%;
  left: 1%;
  position: relative;
  font-weight: normal;
  filter: none;
  -ms-filter: none;
}
.product-details h2.subtitle.fancy span {
  position: relative;
}
.product-details h2.subtitle.fancy span:before,
.product-details h2.subtitle.fancy span:after {
  content: '';
  position: absolute;
  border-top: 3px dashed #bdbbbc;
  width: 600px;
  height: 7px;
  top: 50%;
  margin-top: -1px;
}
.product-details h2.subtitle.fancy span:before {
  right: 100%;
  margin-right: 15px;
}
.product-details h2.subtitle.fancy span:after {
  left: 100%;
  margin-left: 15px;
}
.product-details .optional-step {
  width: 92%;
  left: 1%;
  position: relative;
  background: #b1c4b6;
  margin-bottom: 15px;
}
.product-details .optional-step .optional-top {
  background: url(/SiteFiles/img/step1-top.jpg) no-repeat;
  width: 100%;
  height: 32px;
  display: block;
}
.product-details .optional-step .optional-bottom {
  background: url(/SiteFiles/img/step1-btm.jpg) no-repeat;
  width: 100%;
  height: 27px;
  display: block;
}
.product-details .optional-step .optional-inner h3 {
  color: #346348;
  text-transform: uppercase;
  font-weight: normal;
  margin-bottom: 5px;
}
.product-details .optional-step .optional-inner p {
  font-family: 'Averia Sans Libre', sans-serif;
}
.product-details .optional-step .optional-inner .optional-inner-top {
  padding: 0 15px;
}
.product-details .optional-step .optional-inner .optional-inner-main {
  /*
  Hide checkbox button (the round disc)
  we will use just the label to create pushbutton effect
*/
  /*
  Change the look'n'feel of labels (which are adjacent to checkbox).
  Add some margin, padding to label
*/
  /*
 Change background color for label next to checked radio button
 to make it look like highlighted button
*/
}
.product-details .optional-step .optional-inner .optional-inner-main input[type='checkbox'] {
  display: none;
}
.product-details .optional-step .optional-inner .optional-inner-main input[type='checkbox'] + label {
  display: block;
  margin: 0;
  padding: 4px 12px;
  background: url(/SiteFiles/img/sprite.png) -273px -155px no-repeat;
  position: absolute;
  top: 0;
  left: -16px;
  width: 38px;
  height: 37px;
  cursor: pointer;
}
.product-details .optional-step .optional-inner .optional-inner-main input[type='checkbox']:checked + label {
  background-position: -273px -112px;
}
.product-details .optional-step .optional-inner .optional-inner-main .optional-inner-row.active-row {
  background: #73947c;
}
.product-details .optional-step .optional-inner .optional-inner-main .optional-inner-row.optional-break {
  background-position: 0 19px, 0 0 !important;
  background-repeat: no-repeat !important;
  width: 100%;
  display: block;
  padding-top: 47px;
}
.product-details .optional-step .optional-inner .optional-inner-main .optional-inner-row:first-of-type {
  min-height: 0;
}
.product-details .optional-step .optional-inner .optional-inner-main .options-slide {
  padding: 20px;
  display: none;
}
.product-details .optional-step .optional-inner .optional-inner-main .options-slide p {
  color: #00421b !important;
}
.product-details .optional-step .optional-inner .optional-inner-main .options-slide img {
  margin-bottom: 10px;
}
.product-details .optional-step .optional-inner .optional-inner-main .options-slide .close-options {
  display: block;
  text-align: right;
  text-decoration: underline;
  margin-bottom: 10px;
  font-weight: bold;
  cursor: pointer;
}
.product-details .optional-step .optional-inner .optional-inner-main .options-slide .close-options:hover {
  color: #be7915;
}
.product-details .optional-step .optional-inner .optional-inner-main .rel-header {
  text-transform: uppercase;
  font-size: 100%;
  font-weight: bold;
  color: #346348;
  background-color: #b2c4b6;
  height: 30px;
  line-height: 110%;
  padding-top: 5px;
}
.product-details .optional-step .optional-inner .optional-inner-main .rel-header .arrow {
  float: left;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 11px solid #597f64;
  padding-right: 10px;
}
.product-details .optional-step .optional-inner .optional-inner-main .rel-header .arrow:after {
  display: inline-block;
}
.product-details .optional-step .optional-inner .optional-inner-main .rel-description {
  display: block;
  font-size: 90%;
  line-height: 150%;
  padding: 0 20px 10px !important;
  background-color: #b2c4b6;
}
.product-details .optional-step .optional-inner .optional-inner-main .optional-inner-seperator {
  display: block;
  width: 96%;
  height: 2px;
  background: #000;
  opacity: 0.2;
  margin: 10px 2%;
}
.product-details .optional-step .optional-inner .optional-inner-main .optional-inner-row {
  min-height: 36px;
  margin-bottom: 5px;
}
.product-details .optional-step .optional-inner .optional-inner-main .optional-inner-row p {
  font-family: Arial, sans-serif;
  padding: 7px 0;
  margin: 0;
}
.product-details .optional-step .optional-inner .optional-inner-main .optional-inner-row h5 {
  font-weight: bold;
  font-size: 1.1428em;
  text-transform: uppercase;
  font-family: 'Averia Sans Libre', sans-serif;
}
.product-details .optional-step .optional-inner .optional-inner-main .optional-inner-row .info-icon {
  background: url(/SiteFiles/img/sprite.png) -226px -124px no-repeat;
  width: 35px;
  height: 36px;
  display: inline-block;
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: 50%;
  margin-left: -17px;
}
.product-details .optional-step .optional-inner .optional-inner-main .optional-inner-row .optional-col1 {
  float: left;
  width: 50%;
  font-family: Arial, sans-serif;
  position: relative;
  padding-left: 30px;
}
.product-details .optional-step .optional-inner .optional-inner-main .optional-inner-row .optional-col2 {
  float: left;
  width: 16%;
  margin-left: 1%;
  text-align: center;
  font-family: Arial, sans-serif;
}
.product-details .optional-step .optional-inner .optional-inner-main .optional-inner-row .optional-col2 * {
  text-decoration: line-through;
}
.product-details .optional-step .optional-inner .optional-inner-main .optional-inner-row .optional-col2 h5 {
  text-decoration: none;
}
.product-details .optional-step .optional-inner .optional-inner-main .optional-inner-row .optional-col3 {
  float: left;
  width: 16%;
  margin-left: 1%;
  text-align: center;
  font-family: Arial, sans-serif;
}
.product-details .optional-step .optional-inner .optional-inner-main .optional-inner-row .optional-col4 {
  float: left;
  width: 15%;
  margin-left: 1%;
  text-align: center;
  min-width: 35px;
  position: relative;
}
.product-details .optional-step .optional-inner .optional-inner-main .optional-inner-row.seperator {
  min-height: 20px;
}
.product-details .optional-step .optional-inner .optional-inner-main .optional-inner-row.seperator span {
  margin-top: 0;
}
.product-details .optional-inner-row.struck-out .disabled {
  display: block;
  margin: 0;
  padding: 4px 12px;
  position: absolute;
  top: 0;
  left: -16px;
  width: 38px;
  height: 37px;
  cursor: default;
  z-index: 100;
}
.product-details .optional-inner-row.struck-out.parent * {
  text-decoration: none;
}
.product-details .optional-step h4 {
  font-weight: normal;
  font-size: 1.1428em;
  font-family: Arial, sans-serif;
  text-transform: uppercase;
}
.product-details .optional-step h4 span {
  margin-top: 25px;
  width: 0px;
  height: 0px;
  margin-right: 15px;
  display: inline-block;
}
.product-details .optional-step h4 .triangle {
  margin-top: 0px;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid #00421b;
}
.product-details .optional-step.optional1 .optional-break {
  background-image: url(/SiteFiles/img/step1-top.jpg), url(/SiteFiles/img/step1-btm.jpg) !important;
}
.product-details .optional-step.optional2 {
  background: #c4c2b1;
}
.product-details .optional-step.optional2 h4 span {
  border-left: 7px solid #73715a;
}
.product-details .optional-step.optional2 .optional-inner .rel-header {
  color: #555344;
  background-color: #c4b1b3;
}
.product-details .optional-step.optional2 .optional-inner .rel-header .arrow {
  border-left-color: #7d7b5e;
}
.product-details .optional-step.optional2 .optional-inner .rel-description {
  color: #555344;
  background-color: #c4b1b3;
}
.product-details .optional-step.optional2 .optional-inner-seperator {
  background-color: #555344 !important;
}
.product-details .optional-step.optional2 .optional-inner .optional-inner-main .optional-inner-row {
  color: #555344 !important;
}
.product-details .optional-step.optional2 .optional-inner .optional-inner-main .optional-inner-row p {
  color: #555344 !important;
}
.product-details .optional-step.optional2 .optional-inner .optional-inner-main .optional-inner-row.active-row {
  background: #8a886d;
  color: #fff !important;
}
.product-details .optional-step.optional2 .optional-inner .optional-inner-main .optional-inner-row.active-row p {
  color: #fff !important;
}
.product-details .optional-step.optional2 .optional-inner .optional-inner-main input[type='checkbox'] + label {
  background-position: -313px -154px;
}
.product-details .optional-step.optional2 .optional-inner .optional-inner-main input[type='checkbox']:checked + label {
  background-position: -313px -112px;
}
.product-details .optional-step.optional2 h3,
.product-details .optional-step.optional2 h4,
.product-details .optional-step.optional2 h5,
.product-details .optional-step.optional2 p {
  color: #73715a;
}
.product-details .optional-step.optional2 .optional-top {
  background-image: url(/SiteFiles/img/step2-top.jpg);
}
.product-details .optional-step.optional2 .optional-break {
  background-image: url(/SiteFiles/img/step2-top.jpg), url(/SiteFiles/img/step2-btm.jpg) !important;
}
.product-details .optional-step.optional2 .optional-bottom {
  background-image: url(/SiteFiles/img/step2-btm.jpg);
}
.product-details .optional-step.optional3 {
  background: #c4b1b3;
}
.product-details .optional-step.optional3 h4 span {
  border-left: 7px solid #7e5559;
}
.product-details .optional-step.optional3 .optional-inner .rel-header {
  color: #4e2d30;
  background-color: #cdc4bf;
}
.product-details .optional-step.optional3 .optional-inner .rel-header .arrow {
  border-left-color: #7e5559;
}
.product-details .optional-step.optional3 .optional-inner .rel-description {
  color: #4e2d30;
  background-color: #cdc4bf;
}
.product-details .optional-step.optional3 .optional-inner-seperator {
  background-color: #4e2d30 !important;
}
.product-details .optional-step.optional3 .optional-inner .optional-inner-main .optional-inner-row {
  color: #4e2d30 !important;
}
.product-details .optional-step.optional3 .optional-inner .optional-inner-main .optional-inner-row p {
  color: #4e2d30 !important;
}
.product-details .optional-step.optional3 .optional-inner .optional-inner-main .optional-inner-row.active-row {
  background: #7e5559;
  color: #fff !important;
}
.product-details .optional-step.optional3 .optional-inner .optional-inner-main .optional-inner-row.active-row p {
  color: #fff !important;
}
.product-details .optional-step.optional3 .optional-inner .optional-inner-main input[type='checkbox'] + label {
  background-position: -354px -154px;
}
.product-details .optional-step.optional3 .optional-inner .optional-inner-main input[type='checkbox']:checked + label {
  background-position: -354px -112px;
}
.product-details .optional-step.optional3 h3,
.product-details .optional-step.optional3 h4,
.product-details .optional-step.optional3 h5,
.product-details .optional-step.optional3 p {
  color: #7e5559;
}
.product-details .optional-step.optional3 .optional-top {
  background-image: url(/SiteFiles/img/step3-top.jpg);
}
.product-details .optional-step.optional3 .optional-break {
  background-image: url(/SiteFiles/img/step3-top.jpg), url(/SiteFiles/img/step3-btm.jpg) !important;
}
.product-details .optional-step.optional3 .optional-bottom {
  background-image: url(/SiteFiles/img/step3-btm.jpg);
}
.product-details .optional-step.optional4 {
  background: #cdc4bf;
}
.product-details .optional-step.optional4 h4 span {
  border-left: 7px solid #4d3f38;
}
.product-details .optional-step.optional4 .optional-inner .rel-header {
  color: #4d3f38 !important;
  background-color: #cdc4bf;
}
.product-details .optional-step.optional4 .optional-inner .rel-header .arrow {
  border-left-color: #4d3f38 !important;
}
.product-details .optional-step.optional4 .optional-inner .rel-description {
  color: #4d3f38 !important;
  background-color: #cdc4bf;
}
.product-details .optional-step.optional4 .optional-inner-seperator {
  background-color: #4d3f38 !important;
}
.product-details .optional-step.optional4 .optional-inner .optional-inner-main .optional-inner-row {
  color: #4d3f38 !important;
}
.product-details .optional-step.optional4 .optional-inner .optional-inner-main .optional-inner-row p {
  color: #4d3f38 !important;
}
.product-details .optional-step.optional4 .optional-inner .optional-inner-main .optional-inner-row.active-row {
  background: #4d3f38;
  color: #fff !important;
}
.product-details .optional-step.optional4 .optional-inner .optional-inner-main .optional-inner-row.active-row p {
  color: #fff !important;
}
.product-details .optional-step.optional4 .optional-inner .optional-inner-main input[type='checkbox'] + label {
  background-position: -395px -154px;
}
.product-details .optional-step.optional4 .optional-inner .optional-inner-main input[type='checkbox']:checked + label {
  background-position: -395px -112px;
}
.product-details .optional-step.optional4 h3,
.product-details .optional-step.optional4 h4,
.product-details .optional-step.optional4 h5,
.product-details .optional-step.optional4 p {
  color: #4d3f38;
}
.product-details .optional-step.optional4 .optional-top {
  background-image: url(/SiteFiles/img/step4-top.jpg);
}
.product-details .optional-step.optional4 .optional-break {
  background-image: url(/SiteFiles/img/step4-top.jpg), url(/SiteFiles/img/step4-btm.jpg) !important;
}
.product-details .optional-step.optional4 .optional-bottom {
  background-image: url(/SiteFiles/img/step4-btm.jpg);
}
.product-details .optional-step.optional5 {
  background: #bbb1c4;
}
.product-details .optional-step.optional5 h4 span {
  border-left: 7px solid #563970;
}
.product-details .optional-step.optional5 .optional-inner .rel-header {
  color: #563870;
  background-color: #bab1c4;
}
.product-details .optional-step.optional5 .optional-inner .rel-header .arrow {
  border-left-color: #563870;
}
.product-details .optional-step.optional5 .optional-inner .rel-description {
  color: #563870;
  background-color: #bab1c4;
}
.product-details .optional-step.optional5 .optional-inner-seperator {
  background-color: #563870 !important;
}
.product-details .optional-step.optional5 .optional-inner .optional-inner-main .optional-inner-row {
  color: #563870 !important;
}
.product-details .optional-step.optional5 .optional-inner .optional-inner-main .optional-inner-row p {
  color: #563870 !important;
}
.product-details .optional-step.optional5 .optional-inner .optional-inner-main .optional-inner-row.active-row {
  background: #563870;
  color: #fff !important;
}
.product-details .optional-step.optional5 .optional-inner .optional-inner-main .optional-inner-row.active-row p {
  color: #fff !important;
}
.product-details .optional-step.optional5 .optional-inner .optional-inner-main input[type='checkbox'] + label {
  background-position: -438px -154px;
}
.product-details .optional-step.optional5 .optional-inner .optional-inner-main input[type='checkbox']:checked + label {
  background-position: -438px -112px;
}
.product-details .optional-step.optional5 h3,
.product-details .optional-step.optional5 h4,
.product-details .optional-step.optional5 h5,
.product-details .optional-step.optional5 p {
  color: #563970;
}
.product-details .optional-step.optional5 .optional-top {
  background-image: url(/SiteFiles/img/step5-top.jpg);
}
.product-details .optional-step.optional5 .optional-break {
  background-image: url(/SiteFiles/img/step5-top.jpg), url(/SiteFiles/img/step5-btm.jpg) !important;
}
.product-details .optional-step.optional5 .optional-bottom {
  background-image: url(/SiteFiles/img/step5-btm.jpg);
}
.product-details .total-bottom {
  left: 1%;
  margin-bottom: 15px;
  position: relative;
  width: 92%;
  z-index: 999;
}
.product-details .total-bottom .total-top {
  background: url('/SiteFiles/img/total-top.png') no-repeat;
  display: block;
  height: 49px;
  width: 100%;
}
.product-details .total-bottom .total-btm {
  background: url('/SiteFiles/img/total-btm.jpg') no-repeat;
  display: block;
  height: 27px;
  width: 100%;
}
.product-details .total-bottom .total-inner {
  background: #bf1121;
  padding: 2px 15px;
}
@media (max-width: 768px) {
  .product-details .total-bottom .total-inner__price {
    display: flex;
  }
}
.product-details .total-bottom .total-inner h3 {
  color: #fff;
  font-size: 1.5714em;
  font-family: Arial, sans-serif;
}
.product-details .total-bottom .total-inner .total-col1 {
  float: left;
  width: 24%;
  margin-right: 1%;
}
.product-details .total-bottom .total-inner .total-col1 h3 {
  font-size: 1.2em;
}
.product-details .total-bottom .total-inner .total-col1 span {
  color: #fff;
}
.product-details .total-bottom .total-inner .total-col2 {
  float: left;
  width: 35%;
}
.product-details .total-bottom .total-inner .total-col2 span {
  color: #fff;
}
.product-details .total-bottom .total-inner .total-col2 .vat {
  font-size: 0.5em;
}
.product-details .total-bottom .total-inner .total-col3 {
  display: none;
  float: left;
  width: 22%;
}
.product-details .total-bottom .total-inner .total-col3 .quantity {
  color: #fff;
  font-size: 1.2em;
  margin-right: 6px;
}
.product-details .total-bottom .total-inner .total-col3 input[type='text'] {
  width: 46px;
  border: none;
  border-radius: 0;
  box-shadow: none;
  height: 32px;
  font-size: 18px;
  text-align: center;
  padding: 0;
  line-height: 31px;
  font-weight: bold;
}
@media (max-width: 991px) {
  .product-details .total-bottom .total-inner .total-col3 input[type='text'] {
    display: inline;
  }
}
.product-details .total-bottom .total-inner input[type='submit'] {
  display: none;
  width: auto;
  border-radius: 0;
  box-shadow: none;
  background: #307a1a;
  border: none;
  padding: 7px 15px 25px;
  color: #fff;
  font-family: 'Averia Sans Libre', sans-serif;
  line-height: normal;
  text-align: center;
  float: right;
}
.product-details .total-bottom .total-inner input[type='submit']:hover {
  background: #be7915;
}
.product-details .total-bottom .total-inner .reload {
  color: #fff;
  display: block;
  float: right;
  font-weight: bold;
  line-height: 40px;
  text-align: right;
  width: 27%;
}
.IE8 .product-details-main {
  float: left;
}
.IE8 .product-details-main .select-length {
  float: left;
}
.IE8 .product-details-main .select-length input[type='radio'] {
  float: left !important;
  display: block !important;
  box-shadow: none !important;
  width: 18px !important;
  margin: 6px;
  background: none !important;
  border: none !important;
}
.IE8 .product-details-main .select-length input[type='radio'] + label {
  float: left !important;
  cursor: default !important;
}
.IE8 .product-details .optional-step input[type='checkbox'] {
  float: left !important;
  display: block !important;
  box-shadow: none !important;
  width: 18px !important;
  margin-right: 10px;
  background: none !important;
  border: none !important;
}
.IE8 .product-details .optional-step input[type='checkbox'] + label {
  display: none !important;
}
.IE8 .product-details .optional-step .optional-col1 {
  padding-left: 10px;
}
@media (max-width: 991px) {
  .product-details .bottom,
  .product-details .green-bottom {
    width: 98%;
  }
  .product-details .product-details-main,
  .product-details .product-details-top,
  .product-details h2.subtitle.fancy,
  .product-details .optional-step,
  .product-details .total-bottom {
    width: 98%;
  }
  .product-details .specifications {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .product-details .specifications .angled-spec {
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .product-details .total-bottom .total-inner .total-col3 {
    float: right;
    width: 35%;
    text-align: right;
  }
  .product-details .total-bottom .total-inner .reload {
    line-height: normal;
  }
}
@media (min-width: 768px) {
  .optional-inner-main {
    display: block !important;
  }
}
@media (max-width: 767px) {
  .optional-inner-main {
    display: none;
  }
  .optional-inner-top {
    cursor: pointer;
  }
  .product-details {
    margin-bottom: 15px;
  }
  .picks .filters ul li {
    display: none;
  }
  .product-details .tabAccord {
    display: block;
    cursor: pointer;
    text-align: center;
    padding: 8px 0;
    clear: both;
    border: 1px solid #ddd;
    margin-top: 2px;
    margin-bottom: 2px;
    text-transform: uppercase;
    background: #346348;
    color: #fff;
    font-weight: bold;
    font-family: 'Averia Sans Libre', sans-serif;
    font-size: 1.4em;
  }
  .tabAccord:hover,
  .tabAccord.active {
    background: #bd7610;
    color: #fff;
  }
}
@media (max-width: 480px) {
  .product-details .total-bottom .total-inner {
    text-align: center;
  }
  .product-details .total-bottom .total-inner .total-col1,
  .product-details .total-bottom .total-inner .total-col2,
  .product-details .total-bottom .total-inner input[type='submit'] {
    float: none;
    width: 100%;
    margin-right: 0%;
    margin-bottom: 10px;
  }
  .optional-inner-row {
    font-size: 0.8em;
  }
  .product-details .bottom,
  .product-details .green-bottom {
    width: 100%;
    left: 0;
  }
  .product-details .product-details-main,
  .product-details .product-details-top,
  .product-details h2.subtitle.fancy,
  .product-details .optional-step,
  .product-details .total-bottom {
    width: 100%;
    left: 0;
  }
  .product-details .product-details-top {
    margin-top: 0;
  }
  .picks .tab_content img {
    display: none;
  }
  .product-details .download-area {
    padding: 15px;
  }
  .product-details .download-area .pdf {
    position: static;
    display: block;
    float: left;
  }
  .product-details .download-area .click-here {
    position: static;
    display: block;
    float: right;
  }
  .product-details .total-bottom .total-inner .total-col3 {
    float: none;
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 320px) {
  .product-details .optional-step .optional-inner .optional-inner-main .optional-inner-row .optional-col1 {
    width: 45%;
  }
  .product-details .download-area .click-here {
    width: 80px;
    height: 80px;
    background-position: -402px -10px;
  }
}
@media (max-width: 768px) {
  .pricing-container {
    position: fixed;
    bottom: -15px;
    width: 100%;
    left: 0;
    z-index: 1000;
  }
}
#social-hub {
  min-height: 800px;
  /* ---- isotope ---- */
  /* clear fix */
  /* ---- .element-item ---- */
}
#social-hub .relDiv {
  position: relative;
}
#social-hub .mediaItem {
  position: relative;
  height: 200px;
}
#social-hub .socialBox {
  margin-bottom: 5px;
}
#social-hub .socialBox.tweet {
  background: #00421c;
}
#social-hub .socialBox.facebook {
  background: #7c9c85;
}
#social-hub .socialBox .socialIcon {
  padding: 24px 0 0;
}
#social-hub .socialBox .socialIcon.over {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -20px;
  padding: 0;
  z-index: 9;
}
#social-hub .socialBox .socialIcon span {
  display: block;
  height: 35px;
  width: 35px;
  margin: 0 auto;
}
#social-hub .socialBox .socialIcon span.mediaTwitter {
  background: url(/SiteFiles/img/sprite.png) -556px -145px no-repeat;
}
#social-hub .socialBox .socialIcon span.mediaFacebook {
  background: url(/SiteFiles/img/sprite.png) -592px -145px no-repeat;
}
#social-hub .socialBox .socialIcon span.mediaInstagram {
  background: url(/SiteFiles/img/sprite.png) -632px -151px no-repeat;
}
#social-hub .socialBox .mediaQuote {
  text-align: center;
  padding-top: 20px;
}
#social-hub .socialBox .mediaQuote p {
  color: #fff;
  margin: 0;
  font-size: 1em;
  line-height: normal;
}
#social-hub .socialBox .mediaQuote p.quote {
  position: relative;
  margin: 0;
  display: inline-block;
  width: 100%;
  padding: 0 12px;
  font-family: 'Averia Sans Libre', sans-serif;
}
#social-hub .socialBox .mediaQuote p.quote a {
  color: #fff;
}
#social-hub .filterArea {
  margin-bottom: 20px;
  font-weight: bold;
  font-family: 'Averia Sans Libre', sans-serif;
  font-size: 1.2em;
}
#social-hub .filterArea .filterBy {
  float: left;
  margin: 5px 10px 0 0;
}
#social-hub #social-filter {
  float: left;
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
#social-hub #social-filter li {
  float: left;
  margin: 0 5px;
  padding: 5px 10px;
}
#social-hub #social-filter li.selected,
#social-hub #social-filter li:hover {
  background: url(/SiteFiles/img/socialFilterSelected.png) repeat-y;
  background-size: 100% 100%;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/SiteFiles/img/socialFilterSelected.png', sizingMethod='scale');
  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/SiteFiles/img/socialFilterSelected.png',sizingMethod='scale')";
  cursor: pointer;
}
#social-hub .isotope-item {
  -webkit-transition-duration: 0.8s;
  -moz-transition-duration: 0.8s;
  -ms-transition-duration: 0.8s;
  -o-transition-duration: 0.8s;
  transition-duration: 0.8s;
}
#social-hub .isotope-item {
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform, opacity;
  -ms-transition-property: -ms-transform, opacity;
  -o-transition-property: top, left, opacity;
  transition-property: transform, opacity;
}
#social-hub #filters {
  margin-bottom: 20px;
}
#social-hub #filters a {
  width: 23%;
  margin: 0 1%;
  display: block;
  float: left;
  padding: 10px;
  text-align: center;
  color: #fff;
  background: #00421b;
}
#social-hub #filters a.is-checked {
  background: #00421b;
}
#social-hub #divPostsLoader {
  position: relative;
  left: 50%;
  margin-left: -64px;
  top: 100px;
}
#social-hub .isotope {
  margin: 2px;
  margin-top: 25px;
  position: absolute;
  width: 100%;
}
#social-hub .isotope:after {
  content: '';
  display: block;
  clear: both;
}
#social-hub .element-item {
  position: relative;
  float: left;
  width: 33%;
  height: 221px;
  margin: 0;
  text-align: left;
  overflow: hidden;
  margin-right: -1px;
  margin-bottom: -1px;
}
#social-hub .element-item .overHide {
  overflow: hidden;
  cursor: pointer;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
#social-hub .element-item .relDiv {
  overflow: hidden;
}
#social-hub .element-item .relDiv img {
  /*max-width: initial;
                top: -6px;
                left: -6px;
                position: relative;*/
}
#social-hub .element-item:hover {
  border-color: #00421b;
}
#social-hub .element-item > * {
  margin: 0;
  padding: 0;
}
#social-hub #LoadMore {
  display: none;
  color: #00421b;
  font-family: 'Averia Sans Libre', sans-serif;
  font-weight: bold;
  font-size: 1.4em;
  width: 100%;
  float: left;
  text-align: center;
  margin: 15px 0;
}
.fancybox-title-inside-wrap {
  text-align: center;
}
@media (max-width: 1200px) {
  #social-hub .element-item {
    width: 33%;
  }
}
@media (max-width: 991px) {
  #social-hub .element-item {
    width: 50%;
    height: 213px;
  }
  #social-hub .socialBox .socialIcon {
    padding: 10px 0 0;
  }
  #social-hub .socialBox .mediaQuote {
    padding-top: 5px;
    font-size: 0.9em;
  }
}
@media (max-width: 767px) {
  #social-hub .element-item {
    height: 293px;
  }
  #social-hub .socialBox .socialIcon {
    padding: 30px 0 0;
  }
  #social-hub .socialBox .mediaQuote {
    padding-top: 25px;
    font-size: 1.2em;
  }
  #social-hub .element-item .relDiv img {
    width: 100%;
  }
}
@media (max-width: 480px) {
  #social-hub .element-item {
    width: 100%;
    margin: 0;
  }
}
.educational-map__title {
  padding: 0;
  background: none;
  position: absolute;
  top: 40px;
  left: 70px;
  font-size: 30px;
  color: white;
  text-transform: uppercase;
  font-family: '3dumbregular', Arial, sans-serif;
}
@media (max-width: 991px) {
  .educational-map__title {
    width: auto;
    position: relative;
    top: auto;
    left: auto;
    color: #000;
  }
}
.educational-map__blackboard-text {
  position: absolute;
  top: 110px;
  left: 70px;
  width: 320px;
  font-size: 26px;
  color: white;
  text-transform: uppercase;
}
@media (max-width: 991px) {
  .educational-map__blackboard-text {
    width: auto;
    position: relative;
    top: auto;
    left: auto;
    color: #000;
  }
}
.educational-map__blackboard-text strong {
  display: block;
}
@media (max-width: 991px) {
  .educational-map__blackboard-text strong {
    display: none;
  }
}
.educational-map__red-mark {
  position: absolute;
  top: 105px;
  left: 65px;
}
@media (max-width: 991px) {
  .educational-map__red-mark {
    display: none;
  }
}
.educational-map__curly-arrow {
  position: absolute;
  top: 260px;
  left: 240px;
  z-index: 1;
}
@media (max-width: 991px) {
  .educational-map__curly-arrow {
    display: none;
  }
}
.educational-map__girl {
  position: absolute;
  bottom: 25px;
  left: 80px;
  z-index: 2;
}
@media (max-width: 991px) {
  .educational-map__girl {
    bottom: -5px;
    left: -35px;
    width: 170px;
  }
}
.educational-map__roi {
  position: absolute;
  bottom: 50px;
  right: 260px;
  width: 130px;
  z-index: 0;
}
@media (max-width: 991px) {
  .educational-map__roi {
    display: none;
  }
}
.educational-map__search-pre-text {
  margin-bottom: 0;
  display: inline-block;
  font-size: 22px;
  font-weight: 700;
  color: white;
  vertical-align: middle;
}
@media (max-width: 991px) {
  .educational-map__search-pre-text {
    display: none;
  }
}
.educational-map__dropdown {
  margin: 0 10px;
  display: inline-block;
  width: 200px;
  vertical-align: middle;
  text-align: left;
  height: auto;
  border: none;
  box-shadow: none;
  border-radius: 0;
  padding: 5px;
}
@media (max-width: 767px) {
  .educational-map__dropdown {
    display: block;
    margin: 0 auto 10px;
  }
}
.educational-map__submit {
  display: inline-block;
  color: white;
  background-color: #5d7e32;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 700;
  width: 120px;
  height: auto;
  border: none;
  box-shadow: none;
  border-radius: 0;
  padding: 0 5px;
  vertical-align: middle;
}
.educational-map__image1 {
  position: absolute;
  top: 20px;
  right: 140px;
  z-index: 10;
}
@media (max-width: 991px) {
  .educational-map__image1 {
    position: relative;
    top: auto;
    right: auto;
    display: block;
    margin: 50px auto 20px;
  }
}
.educational-map__image2 {
  position: absolute;
  bottom: -130px;
  right: -20px;
  z-index: 10;
}
@media (max-width: 991px) {
  .educational-map__image2 {
    position: relative;
    bottom: auto;
    right: auto;
    display: block;
    margin: 20px auto;
  }
}
.educational-map__post-it-text {
  position: absolute;
  top: 65px;
  left: 90px;
  width: 220px;
}
.educational-map__base-text {
  margin-bottom: 50px;
}
.educational-map__base-text a {
  text-decoration: underline;
}
.educational-map__blackboard-area {
  position: relative;
}
@media (max-width: 991px) {
  .educational-map__blackboard-image {
    display: none;
  }
}
.educational-map__map-svg {
  position: relative;
  z-index: 1;
}
@media (max-width: 991px) {
  .educational-map__map-svg {
    display: none;
  }
}
.educational-map__map-image {
  display: none;
}
@media (max-width: 991px) {
  .educational-map__map-image {
    display: block;
    margin: 0 auto;
    position: relative;
    right: -35px;
  }
}
.educational-map__search-area {
  margin: 0 1px;
  background-image: url('/SiteFiles/img/educational/search-bg.jpg');
  text-align: center;
  padding: 10px;
}
.educational-map__search-area .chzn-container {
  width: 200px !important;
  margin: 0 10px;
  vertical-align: middle;
  text-align: left;
}
.educational-map__info-area {
  background-image: url('/SiteFiles/img/educational/desktop-blue-line.png');
  background-position: 45px top;
  background-repeat: no-repeat;
  position: relative;
  min-height: 300px;
  margin-bottom: 200px;
}
@media (max-width: 991px) {
  .educational-map__info-area {
    background: none;
    width: 100%;
    text-align: center;
    top: 0;
    padding-top: 1px;
    padding-bottom: 80px;
    margin-bottom: 320px;
  }
  .educational-map__info-area:before {
    -ms-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    position: absolute;
    top: 0;
    left: 50%;
    content: '';
    width: 40px;
    height: 100%;
    background-color: #6bbad0;
  }
}
.educational-map__post-it-holder {
  position: absolute;
  bottom: -170px;
  left: -70px;
  z-index: 10;
}
@media (max-width: 991px) {
  .educational-map__post-it-holder {
    -ms-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: -320px;
    left: 50%;
    margin: 20px 0;
  }
}
@media (max-width: 991px) {
  .educational-map__post-it-holder img {
    max-width: none;
  }
}
.educational-map__map-holder {
  position: absolute;
  top: 30px;
  right: -10px;
}
@media (max-width: 991px) {
  .educational-map__map-holder {
    position: relative;
    top: auto;
    right: auto;
    text-align: right;
  }
}
.educational-map__map-tooltip {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 8px;
  background-color: #FFF;
  color: #000;
  display: none;
  z-index: 10;
}
.educational-map__map-tooltip:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #FFF;
  border-width: 10px;
  margin-left: -10px;
}
.educational-map__map-tooltip.is-active {
  display: block;
}
@media (max-width: 991px) {
  .educational-map__map-tooltip.is-active {
    display: none;
  }
}
.educational-map__region-menu {
  -ms-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  background-color: #7d9c86;
  padding: 80px 20px 100px;
  max-width: 430px;
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: 10000;
  overflow: auto;
}
.educational-map__region-menu ul {
  list-style: none;
  padding-left: 30px;
  margin-bottom: 50px;
}
.educational-map__region-menu li {
  padding: 7px 0;
  color: #FFF;
  display: block;
}
.educational-map__region-menu.is-active {
  -ms-transform: translateX(0);
  -moz-transform: translateX(0);
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.educational-map__close-menu-btn {
  position: absolute;
  right: 0;
  top: 0;
  color: #FFF;
  background-color: #213d27;
  padding: 10px 15px;
  font-size: 27px;
  font-weight: 700;
  display: inline-block;
  width: auto;
  height: auto;
  border-radius: 0;
  box-shadow: none;
  border: none;
}
.educational-map__region-menu-title {
  font-size: 27px;
  font-weight: 700;
  padding-left: 30px;
  color: #FFF;
  text-transform: uppercase;
}
.side-menu-update {
  position: fixed;
  top: 10px;
}
.christmas-banner {
  width: 100%;
  height: 328px;
  background-image: url('/SiteFiles/img/christmas-banner.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left top;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: flex-end;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
}
.christmas-banner p {
  font-family: 'Averia Sans Libre', sans-serif;
  font-size: 21px;
  color: #fff;
  text-align: center;
}
@media (min-width: 769px) {
  .christmas-banner p {
    padding: 5px 20%;
  }
}
.christmas-banner p strong {
  background-color: #ff0000;
  padding: 5px;
  margin-bottom: -25px;
  display: block;
}
.tabbed-content-container {
  padding-bottom: 50px;
}
.tabbed-content-container .tabs {
  display: flex;
}
@media (max-width: 769px) {
  .tabbed-content-container .tabs.desktop-only {
    display: none;
  }
}
@media (max-width: 769px) {
  .tabbed-content-container .tabs {
    flex-direction: column;
  }
}
.tabbed-content-container .tab {
  background-color: #346348;
  text-align: center;
  padding: 15px 5px;
  border: 1px solid #346348;
  margin: 0 2px;
  flex: auto;
  color: #ffffff;
  font-size: 12px;
  font-family: 'Averia Sans Libre', sans-serif;
  z-index: 10;
}
@media (max-width: 769px) {
  .tabbed-content-container .tab {
    font-size: 20px;
    margin-top: 5px;
  }
}
@media (min-width: 769px) {
  .tabbed-content-container .tab.active {
    background-color: #ffffff;
    border-bottom: none;
    color: #346348;
  }
}
.tabbed-content-container .tabbed-content {
  min-height: 270px;
  border: 1px solid #346348;
  margin: 0 2px;
  border-top: none;
}
@media (max-width: 769px) {
  .tabbed-content-container .tabbed-content {
    min-height: 0px;
  }
}
.tabbed-content-container .tabbed-content__inner {
  display: none;
  padding: 35px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
@media (max-width: 769px) {
  .tabbed-content-container .tabbed-content__inner {
    min-height: 0px;
    padding: 0 35px;
    display: block;
    height: 0;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
}
.tabbed-content-container .tabbed-content__inner.active {
  display: flex;
  display: -webkit-flex;
  opacity: 1;
}
@media (max-width: 769px) {
  .tabbed-content-container .tabbed-content__inner.active {
    display: block;
    height: 100%;
    padding: 35px;
    opacity: 1;
  }
  .tabbed-content-container .tabbed-content__inner.active .tabbed-content__text {
    height: 100%;
    opacity: 1;
  }
  .tabbed-content-container .tabbed-content__inner.active .tabbed-content__img {
    height: 100%;
    opacity: 1;
  }
}
.tabbed-content-container .tabbed-content__text {
  flex: 1 0 49%;
  margin-right: 20px;
}
@media (max-width: 769px) {
  .tabbed-content-container .tabbed-content__text {
    height: 0;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
}
.tabbed-content-container .tabbed-content__text a {
  color: #be7915;
  text-decoration: underline;
}
.tabbed-content-container .tabbed-content__img {
  flex: 1 0 40%;
}
@media (max-width: 769px) {
  .tabbed-content-container .tabbed-content__img {
    margin-top: 10px;
    height: 0;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
}
.chzn-drop {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100% !important;
  /* Firefox, other Gecko */
  /* Opera/IE 8+ */
  /* Safari/Chrome, other WebKit */
}
/* @group Base */
.chzn-container {
  position: relative;
  display: inline-block;
  *display: inline;
  margin-bottom: 2px;
  width: 100% !important;
  width: 100%;
  font-size: 13px;
  -ms-zoom: 1;
  zoom: 1;
}
.chzn-container .chzn-drop {
  position: absolute;
  top: 29px;
  left: 0;
  z-index: 1010;
  border: 1px solid #e3e3e3;
  border-top: 0;
  background: #fff;
  -moz-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
}
/* @end */
/* @group Single Chosen */
.chzn-container-single .chzn-single {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 0 0 0 6px;
  height: 29px;
  border: 1px solid #e3e3e3;
  background-color: #ffffff;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -moz-box-shadow: 0 0 3px #ffffff inset, 0 1px 1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 3px #ffffff inset, 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 3px #ffffff inset, 0 1px 1px rgba(0, 0, 0, 0.1);
  color: #444444;
  text-decoration: none;
  white-space: nowrap;
  line-height: 24px;
}
.chzn-container-single .chzn-default {
  color: #727272;
}
.chzn-container-single .chzn-single span {
  display: block;
  overflow: hidden;
  margin-right: 31px;
  color: #727272;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 210%;
}
.chzn-container-single .chzn-single abbr {
  position: absolute;
  top: 6px;
  right: 26px;
  display: block;
  width: 12px;
  height: 12px;
  background: url('/SiteFiles/img/chosen-sprite.png') -42px 1px no-repeat;
  font-size: 1px;
}
.chzn-container-single .chzn-single abbr:hover,
.chzn-container-single .chzn-single abbr:focus {
  background-position: -42px -10px;
}
.chzn-container-single.chzn-disabled .chzn-single abbr:hover,
.chzn-container-single.chzn-disabled .chzn-single abbr:focus {
  background-position: -42px -10px;
}
.chzn-container-single .chzn-single div {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 31px;
  height: 100%;
  background: #fff;
}
.chzn-container-single .chzn-single div b {
  display: block;
  width: 100%;
  height: 100%;
  background: url('/SiteFiles/img/svg/angle-down-icon.svg') no-repeat center;
}
.chzn-search {
  display: none;
}
.chzn-container-single .chzn-search input {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 1px 0;
  padding: 4px 20px 4px 5px;
  width: 100% !important;
  outline: 0;
  border: 1px solid #e3e3e3;
  background: #fff url('/SiteFiles/img/chosen-sprite.png') no-repeat 100% -20px;
  background: url('/SiteFiles/img/chosen-sprite.png') no-repeat 100% -20px, -moz-linear-gradient(top, #eeeeee 1%, #ffffff 15%);
  background: url('/SiteFiles/img/chosen-sprite.png') no-repeat 100% -20px, -o-linear-gradient(top, #eeeeee 1%, #ffffff 15%);
  background: url('/SiteFiles/img/chosen-sprite.png') no-repeat 100% -20px, -webkit-gradient(linear, 0 0, 0 100%, color-stop(1%, #eeeeee), color-stop(15%, #ffffff));
  background: url('/SiteFiles/img/chosen-sprite.png') no-repeat 100% -20px, -webkit-linear-gradient(top, #eeeeee 1%, #ffffff 15%);
  background: url('/SiteFiles/img/chosen-sprite.png') no-repeat 100% -20px, linear-gradient(#eeeeee 1%, #ffffff 15%);
  font-family: sans-serif;
  /* Firefox, other Gecko */
  /* Opera/IE 8+ */
  /* Safari/Chrome, other WebKit */
}
.chzn-container-single .chzn-drop {
  -moz-border-radius: 0 0 4px 4px;
  -webkit-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
/* @end */
.chzn-container-single-nosearch .chzn-search input {
  position: absolute;
  left: -9000px;
}
/* @group Multi Chosen */
.chzn-container-multi .chzn-choices {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 1%;
  height: auto !important;
  border: 1px solid #e3e3e3;
  background-color: #fff;
  background-image: -moz-linear-gradient(top, #eeeeee 1%, #ffffff 15%);
  background-image: -o-linear-gradient(top, #eeeeee 1%, #ffffff 15%);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, color-stop(1%, #eeeeee), color-stop(15%, #ffffff));
  background-image: -webkit-linear-gradient(top, #eeeeee 1%, #ffffff 15%);
  background-image: linear-gradient(#eeeeee 1%, #ffffff 15%);
  cursor: text;
}
.chzn-container-multi .chzn-choices li {
  float: left;
  list-style: none;
}
.chzn-container-multi .chzn-choices .search-field {
  margin: 0;
  padding: 0;
  white-space: nowrap;
}
.chzn-container-multi .chzn-choices .search-field input {
  margin: 1px 0;
  padding: 5px;
  height: 15px;
  outline: 0;
  border: 0 !important;
  background: transparent !important;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #666;
  font-family: sans-serif;
}
.chzn-container-multi .chzn-choices .search-field .default {
  color: #999;
}
.chzn-container-multi .chzn-choices .search-choice {
  position: relative;
  margin: 3px 0 3px 5px;
  padding: 3px 20px 3px 5px;
  border: 1px solid #e3e3e3;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-color: #e4e4e4;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -moz-box-shadow: 0 0 2px #ffffff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 0 2px #ffffff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 2px #ffffff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
  color: #333;
  line-height: 13px;
  cursor: default;
  background-image: linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eeeeee 100%);
}
.chzn-container-multi .chzn-choices .search-choice.search-choice-disabled {
  padding-right: 5px;
  border: 1px solid #cccccc;
  background-color: #e4e4e4;
  color: #666;
  background-image: linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eeeeee 100%);
}
.chzn-container-multi .chzn-choices .search-choice-focus {
  background: #d4d4d4;
}
.chzn-container-multi .chzn-choices .search-choice .search-choice-close {
  position: absolute;
  top: 4px;
  right: 3px;
  display: block;
  width: 12px;
  height: 12px;
  background: url('/SiteFiles/img/chosen-sprite.png') -42px 1px no-repeat;
  font-size: 1px;
}
.chzn-container-multi .chzn-choices .search-choice .search-choice-close:hover,
.chzn-container-multi .chzn-choices .search-choice .search-choice-close:focus {
  background-position: -42px -10px;
}
.chzn-container-multi .chzn-choices .search-choice-focus .search-choice-close {
  background-position: -42px -10px;
}
/* @end */
/* @group Results */
.chzn-container .chzn-results {
  -webkit-overflow-scrolling: touch;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  max-height: 240px;
}
.chzn-container-multi .chzn-results {
  margin: -1px 0 0;
  padding: 0;
}
.chzn-container .chzn-results li {
  color: #627168;
  display: none;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 5px 6px;
  list-style: none;
  line-height: 15px;
  /* Firefox, other Gecko */
  /* Opera/IE 8+ */
  /* Safari/Chrome, other WebKit */
}
.chzn-container .chzn-results .active-result {
  display: list-item;
  cursor: pointer;
}
.chzn-container .chzn-results .highlighted {
  background: #c5862d;
  color: #fff;
  /* Old browsers */
}
.chzn-container .chzn-results li em {
  background: #feffde;
  font-style: normal;
}
.chzn-container .chzn-results .highlighted em {
  background: transparent;
}
.chzn-container .chzn-results .no-results {
  display: list-item;
  background: #f4f4f4;
}
.chzn-container .chzn-results .group-result {
  color: #999;
  font-weight: bold;
  cursor: default;
}
.chzn-container .chzn-results .group-option {
  padding-left: 15px;
}
.chzn-container-multi .chzn-drop .result-selected {
  display: none;
}
.chzn-container .chzn-results-scroll {
  position: absolute;
  z-index: 1;
  margin: 0 4px;
  width: 321px;
  background: white;
  text-align: center;
  /* This should by dynamic with js */
}
.chzn-container .chzn-results-scroll span {
  display: inline-block;
  width: 9px;
  height: 17px;
  text-indent: -5000px;
}
.chzn-container .chzn-results-scroll-down {
  bottom: 0;
}
.chzn-container .chzn-results-scroll-down span {
  background: url('/SiteFiles/img/chosen-sprite.png') no-repeat -4px -3px;
}
.chzn-container .chzn-results-scroll-up span {
  background: url('/SiteFiles/img/chosen-sprite.png') no-repeat -22px -3px;
}
/* @end */
/* @group Active  */
.chzn-container-active .chzn-single {
  border: 1px solid #fff;
}
.chzn-container-active .chzn-single-with-drop div {
  border-left: none;
  background: #fff;
}
.chzn-container-active .chzn-single-with-drop div b {
  transform: rotate(180deg);
}
.chzn-container-active .chzn-choices {
  border: 1px solid #cad946;
  -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.chzn-container-active .chzn-choices .search-field input {
  color: #111 !important;
}
/* @end */
/* @group Disabled Support */
.chzn-disabled {
  opacity: 0.5 !important;
  cursor: default;
}
.chzn-disabled .chzn-single {
  cursor: default;
}
.chzn-disabled .chzn-choices .search-choice .search-choice-close {
  cursor: default;
}
/* @group Right to Left */
.chzn-rtl {
  text-align: right;
}
.chzn-rtl .chzn-single {
  overflow: visible;
  padding: 0 8px 0 0;
}
.chzn-rtl .chzn-single span {
  margin-right: 0;
  margin-left: 26px;
  direction: rtl;
}
.chzn-rtl .chzn-single div {
  right: auto;
  left: 3px;
}
.chzn-rtl .chzn-single abbr {
  right: auto;
  left: 26px;
}
.chzn-rtl .chzn-choices .search-field input {
  direction: rtl;
}
.chzn-rtl .chzn-choices li {
  float: right;
}
.chzn-rtl .chzn-choices .search-choice {
  margin: 3px 5px 3px 0;
  padding: 3px 5px 3px 19px;
}
.chzn-rtl .chzn-choices .search-choice .search-choice-close {
  right: auto;
  left: 4px;
}
.chzn-rtl.chzn-container-single .chzn-results {
  margin: 0 0 4px 4px;
  padding: 0 4px 0 0;
}
.chzn-rtl .chzn-results .group-option {
  padding-right: 15px;
  padding-left: 0;
}
.chzn-rtl.chzn-container-active .chzn-single-with-drop div {
  border-right: none;
}
.chzn-rtl .chzn-search input {
  padding: 4px 5px 4px 20px;
  background: #fff url('/SiteFiles/img/chosen-sprite.png') no-repeat -30px -20px;
  background: url('/SiteFiles/img/chosen-sprite.png') no-repeat -30px -20px, -moz-linear-gradient(top, #eeeeee 1%, #ffffff 15%);
  background: url('/SiteFiles/img/chosen-sprite.png') no-repeat -30px -20px, -o-linear-gradient(top, #eeeeee 1%, #ffffff 15%);
  background: url('/SiteFiles/img/chosen-sprite.png') no-repeat -30px -20px, -webkit-gradient(linear, 0 0, 0 100%, color-stop(1%, #eeeeee), color-stop(15%, #ffffff));
  background: url('/SiteFiles/img/chosen-sprite.png') no-repeat -30px -20px, -webkit-linear-gradient(top, #eeeeee 1%, #ffffff 15%);
  background: url('/SiteFiles/img/chosen-sprite.png') no-repeat -30px -20px, linear-gradient(#eeeeee 1%, #ffffff 15%);
  direction: rtl;
}
.chzn-container-single.chzn-rtl .chzn-single div b {
  background-position: 6px 2px;
}
.chzn-container-single.chzn-rtl .chzn-single-with-drop div b {
  background-position: -12px 2px;
}
/* @end */
/*****************/
/** jNotify CSS **/
/*****************/
.sweet-container .jNotify,
#jNotify {
  background: #356347;
  color: #fff;
  padding: 60px 25px 25px;
  margin: 15px 15px 15px 80px;
  width: 20%;
  z-index: 9999;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
}
.sweet-container .jNotify .sweet-spacer,
#jNotify .sweet-spacer {
  display: none!important;
}
.sweet-container .jNotify .sweet-confirm,
#jNotify .sweet-confirm {
  float: right;
  padding: 15px 25px;
  color: #255337;
  background: #fff;
  margin: 20px 0 0 0;
  opacity: .5;
  height: auto;
  box-shadow: none;
}
@media (max-width: 767px) {
  .sweet-container .jNotify,
  #jNotify {
    margin: 15px;
  }
}
#jNotify a {
  color: #35517c !important;
  text-decoration: none;
}
#jNotify span {
  float: right;
  padding: 15px 25px;
  color: #255337;
  background: #fff;
  margin: 20px 0 0 0;
  opacity: .5;
}
/******************/
/** jSuccess CSS **/
/******************/
#jSuccess {
  position: absolute;
  background: #E6EFC2 url('/sitefiles/img/jn-success.png') no-repeat 15px center;
  color: #264409;
  border: 1px solid #C6D880;
  padding: 10px;
  padding-left: 50px;
  margin: 15px;
  z-index: 9999;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
}
#jSuccess a {
  color: #264409 !important;
  text-decoration: none;
}
/****************/
/** jError CSS **/
/****************/
#jError {
  position: absolute;
  background: #FBE3E4 url('/sitefiles/img/jn-error.png') no-repeat 15px center;
  color: #8a1f11;
  border: 1px solid #FBC2C4;
  padding: 10px;
  padding-left: 50px;
  margin: 15px;
  z-index: 9999;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
}
#jError a {
  color: #8a1f11 !important;
  text-decoration: none;
}
/** OVERLAY **/
#jOverlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
}
/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top;
}
.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020;
}
.fancybox-skin {
  position: relative;
  background: #f9f9f9;
  color: #444;
  text-shadow: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.fancybox-opened {
  z-index: 10001;
}
.fancybox-opened .fancybox-skin {
  -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
}
.fancybox-outer,
.fancybox-inner {
  position: relative;
}
.fancybox-inner {
  overflow: hidden;
}
.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch;
}
.fancybox-error {
  color: #444;
  font: 14px/20px 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 15px;
  white-space: nowrap;
}
.fancybox-image,
.fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%;
}
.fancybox-image {
  max-width: 100%;
  max-height: 100%;
}
#fancybox-loading,
.fancybox-close,
.fancybox-prev span,
.fancybox-next span {
  background-image: url('/SiteFiles/img/fancybox/fancybox_sprite.png');
}
#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 8060;
}
#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url('/SiteFiles/img/fancybox/fancybox_loading.gif') center center no-repeat;
}
.fancybox-close {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040;
}
.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: transparent url('/SiteFiles/img/fancybox/blank.gif');
  /* helps IE */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 8040;
}
.fancybox-prev {
  left: 0;
}
.fancybox-next {
  right: 0;
}
.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 34px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 8040;
  visibility: hidden;
}
.fancybox-prev span {
  left: 10px;
  background-position: 0 -36px;
}
.fancybox-next span {
  right: 10px;
  background-position: 0 -72px;
}
.fancybox-nav:hover span {
  visibility: visible;
}
.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  visibility: hidden;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important;
}
/* Overlay helper */
.fancybox-lock {
  overflow: hidden !important;
  width: auto;
}
.fancybox-lock body {
  overflow: hidden !important;
}
.fancybox-lock-test {
  overflow-y: hidden !important;
}
.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 10000;
  background: url('/SiteFiles/img/fancybox/fancybox_overlay.png');
}
.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
}
.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll;
}
/* Title helper */
.fancybox-title {
  visibility: hidden;
  font: normal 13px/20px 'Helvetica Neue', Helvetica, Arial, sans-serif;
  position: relative;
  text-shadow: none;
  z-index: 8050;
}
.fancybox-opened .fancybox-title {
  visibility: visible;
}
.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8050;
  text-align: center;
}
.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent;
  /* Fallback for web browsers that doesn't support RGBa */
  background: rgba(0, 0, 0, 0.8);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #fff;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap;
}
.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff;
}
.fancybox-title-inside-wrap {
  padding-top: 10px;
}
.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, 0.8);
}
.fancybox-media {
  width: 100%;
  text-align: center;
  position: relative;
}
.fancybox-media i {
  margin: auto;
  top: -50%;
  width: 80%;
  position: absolute;
  color: #d1d7d3;
  z-index: 10;
}
.video-container .fancybox-media i {
  width: 100%;
}
.youtube-vid .fancybox-media i {
  top: 35%;
}
@media only screen and (max-width: 768px) {
  .youtube-vid .fancybox-media i {
    top: 30%;
  }
}
@media only screen and (max-width: 320px) {
  .youtube-vid .fancybox-media i {
    top: 25%;
  }
}
/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  #fancybox-loading,
  .fancybox-close,
  .fancybox-prev span,
  .fancybox-next span {
    background-image: url('/SiteFiles/img/fancybox/fancybox_sprite@2x.png');
    background-size: 44px 152px;
    /*The size of the normal image, half the size of the hi-res image*/
  }
  #fancybox-loading div {
    background-image: url('/SiteFiles/img/fancybox/fancybox_loading@2x.gif');
    background-size: 24px 24px;
    /*The size of the normal image, half the size of the hi-res image*/
  }
}
/*
 * 	Core Owl Carousel CSS File
 *	v1.3.2
 */
/* clearfix */
.owl-carousel .owl-wrapper:after {
  content: '.';
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
/* display none until init */
.owl-carousel {
  z-index: 10;
  display: none;
  position: relative;
  width: 96%;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  margin-bottom: 0px;
}
.owl-carousel .owl-wrapper {
  display: none;
  position: relative;
  -webkit-transform: translate3d(0px, 0px, 0px);
  -moz-transform: translate3d(0px, 0px, 0px);
  -ms-transform: translate3d(0px, 0px, 0px);
  -o-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper-outer {
  overflow: hidden;
  position: relative;
  width: 100%;
}
.owl-carousel .owl-wrapper-outer.autoHeight {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out;
}
.owl-carousel .owl-item {
  float: left;
  padding: 30px 40px;
  position: relative;
}
.home-carousel .owl-wrapper-outer .owl-wrapper .owl-item {
  max-height: 290px;
}
@media (max-width: 1200px) {
  .home-carousel .owl-wrapper-outer .owl-wrapper .owl-item {
    max-height: none;
  }
}
.owl-carousel .owl-item a {
  float: left;
  width: 100%;
  height: 100%;
}
.owl-controls .owl-page,
.owl-controls .owl-buttons div {
  cursor: pointer;
}
.owl-controls {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
/* mouse grab icon */
.grabbing {
  cursor: url(/SiteFiles/img/grabbing.png) 8 8, move;
}
/* fix */
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.item-text {
  float: left;
  width: 49%;
  margin-right: 2%;
}
.item-text.youtube-vid {
  width: 59%;
}
.item-text.youtube-vid a {
  padding: 0 10%;
}
.item-text.youtube-vid a img {
  border: 3px solid #fff;
}
@media (max-width: 600px) {
  .item-text.youtube-vid {
    width: 100%;
  }
}
.item-image {
  width: 49%;
  float: left;
}
.item-image.youtube-vid {
  width: 39%;
}
@media (max-width: 600px) {
  .item-image {
    width: 100%;
  }
}
.item-youtube {
  bottom: 32px;
  right: 32px;
  position: absolute;
}
.item-text h2 {
  color: #fff;
  font-size: 26px;
  text-transform: uppercase;
  margin-bottom: 20px;
  background: none;
  line-height: normal;
  padding: 0;
  filter: none;
  -ms-filter: none;
}
.item-text h3 {
  color: #fff;
  font-size: 16px;
  font-weight: normal;
}
.item-cta {
  display: inline-block;
  font-size: 16px;
  color: #00421b;
  background-color: #ffffff;
  padding: 4px 10px;
  margin-top: 22px;
}
.item-cta:hover {
  background-color: #be7915;
  color: #ffffff;
}
.bannerbg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: -2;
  object-fit: cover;
}
.bannerouter {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: -1;
}
.owl-theme .owl-controls {
  margin-top: 5px;
  text-align: center;
}
/* Styling Next and Prev buttons */
/*.owl-theme .owl-controls .owl-buttons div{
	color: #FFF;
	display: inline-block;
	zoom: 1;
	margin: 5px;
	padding: 3px 10px;
	font-size: 12px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	background: #869791;
	opacity: 0.5;
}*/
.owl-theme .owl-buttons {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.owl-theme .owl-pagination {
  position: absolute;
  left: -25px;
  width: 100%;
  text-align: right;
  top: 10px;
}
.owl-theme .owl-controls .owl-buttons .owl-prev {
  width: 39px;
  height: 39px;
  display: block;
  position: absolute;
  top: 50%;
  left: -14px;
  background: url(/SiteFiles/img/sprite.png) 1px -63px no-repeat;
  margin-top: -20px;
  text-indent: -2000em;
}
.owl-theme .owl-controls .owl-buttons .owl-next {
  width: 39px;
  height: 39px;
  display: block;
  position: absolute;
  top: 50%;
  right: -12px;
  background: url(/SiteFiles/img/sprite.png) -54px -63px no-repeat;
  margin-top: -20px;
  text-indent: -2000em;
}
/* Clickable class fix problem with hover on touch devices */
/* Use it for non-touch hover action */
.owl-theme .owl-controls.clickable .owl-buttons div:hover {
  opacity: 1;
  text-decoration: none;
}
/* Styling Pagination*/
.owl-theme .owl-controls .owl-page {
  display: inline-block;
  zoom: 1;
  *display: inline;
  /*IE7 life-saver */
}
.owl-theme .owl-controls .owl-page span {
  display: block;
  width: 18px;
  height: 10px;
  margin: 5px 1px;
  background: #72904a;
}
.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span {
  background: #00421b;
}
/* If PaginationNumbers is true */
.owl-theme .owl-controls .owl-page span.owl-numbers {
  height: auto;
  width: auto;
  color: #fff;
  padding: 2px 10px;
  font-size: 12px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}
/* preloading images */
.owl-item.loading {
  min-height: 150px;
  background: url(/SiteFiles/img/AjaxLoader.gif) no-repeat center center;
}
@media (max-width: 600px) {
  .item-text,
  .item-image .item-text.youtube-vid,
  .item-image.youtube-vid {
    float: none;
    width: 100%;
    margin-right: 0%;
    margin-bottom: 2%;
  }
}
/* 
 *  Owl Carousel CSS3 Transitions 
 *  v1.3.2
 */
.owl-origin {
  -webkit-perspective: 1200px;
  -webkit-perspective-origin-x: 50%;
  -webkit-perspective-origin-y: 50%;
  -moz-perspective: 1200px;
  -moz-perspective-origin-x: 50%;
  -moz-perspective-origin-y: 50%;
  perspective: 1200px;
}
/* fade */
.owl-fade-out {
  z-index: 10;
  -webkit-animation: fadeOut .7s both ease;
  -moz-animation: fadeOut .7s both ease;
  animation: fadeOut .7s both ease;
}
.owl-fade-in {
  -webkit-animation: fadeIn .7s both ease;
  -moz-animation: fadeIn .7s both ease;
  animation: fadeIn .7s both ease;
}
/* backSlide */
.owl-backSlide-out {
  -webkit-animation: backSlideOut 1s both ease;
  -moz-animation: backSlideOut 1s both ease;
  animation: backSlideOut 1s both ease;
}
.owl-backSlide-in {
  -webkit-animation: backSlideIn 1s both ease;
  -moz-animation: backSlideIn 1s both ease;
  animation: backSlideIn 1s both ease;
}
/* goDown */
.owl-goDown-out {
  -webkit-animation: scaleToFade .7s ease both;
  -moz-animation: scaleToFade .7s ease both;
  animation: scaleToFade .7s ease both;
}
.owl-goDown-in {
  -webkit-animation: goDown .6s ease both;
  -moz-animation: goDown .6s ease both;
  animation: goDown .6s ease both;
}
/* scaleUp */
.owl-fadeUp-in {
  -webkit-animation: scaleUpFrom .5s ease both;
  -moz-animation: scaleUpFrom .5s ease both;
  animation: scaleUpFrom .5s ease both;
}
.owl-fadeUp-out {
  -webkit-animation: scaleUpTo .5s ease both;
  -moz-animation: scaleUpTo .5s ease both;
  animation: scaleUpTo .5s ease both;
}
/* Keyframes */
/*empty*/
@-webkit-keyframes empty {
  0% {
    opacity: 1;
  }
}
@-moz-keyframes empty {
  0% {
    opacity: 1;
  }
}
@keyframes empty {
  0% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes backSlideOut {
  25% {
    opacity: .5;
    -webkit-transform: translateZ(-500px);
  }
  75% {
    opacity: .5;
    -webkit-transform: translateZ(-500px) translateX(-200%);
  }
  100% {
    opacity: .5;
    -webkit-transform: translateZ(-500px) translateX(-200%);
  }
}
@-moz-keyframes backSlideOut {
  25% {
    opacity: .5;
    -moz-transform: translateZ(-500px);
  }
  75% {
    opacity: .5;
    -moz-transform: translateZ(-500px) translateX(-200%);
  }
  100% {
    opacity: .5;
    -moz-transform: translateZ(-500px) translateX(-200%);
  }
}
@keyframes backSlideOut {
  25% {
    opacity: .5;
    transform: translateZ(-500px);
  }
  75% {
    opacity: .5;
    transform: translateZ(-500px) translateX(-200%);
  }
  100% {
    opacity: .5;
    transform: translateZ(-500px) translateX(-200%);
  }
}
@-webkit-keyframes backSlideIn {
  0%,
  25% {
    opacity: .5;
    -webkit-transform: translateZ(-500px) translateX(200%);
  }
  75% {
    opacity: .5;
    -webkit-transform: translateZ(-500px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateZ(0) translateX(0);
  }
}
@-moz-keyframes backSlideIn {
  0%,
  25% {
    opacity: .5;
    -moz-transform: translateZ(-500px) translateX(200%);
  }
  75% {
    opacity: .5;
    -moz-transform: translateZ(-500px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateZ(0) translateX(0);
  }
}
@keyframes backSlideIn {
  0%,
  25% {
    opacity: .5;
    transform: translateZ(-500px) translateX(200%);
  }
  75% {
    opacity: .5;
    transform: translateZ(-500px);
  }
  100% {
    opacity: 1;
    transform: translateZ(0) translateX(0);
  }
}
@-webkit-keyframes scaleToFade {
  to {
    opacity: 0;
    -webkit-transform: scale(0.8);
  }
}
@-moz-keyframes scaleToFade {
  to {
    opacity: 0;
    -moz-transform: scale(0.8);
  }
}
@keyframes scaleToFade {
  to {
    opacity: 0;
    transform: scale(0.8);
  }
}
@-webkit-keyframes goDown {
  from {
    -webkit-transform: translateY(-100%);
  }
}
@-moz-keyframes goDown {
  from {
    -moz-transform: translateY(-100%);
  }
}
@keyframes goDown {
  from {
    transform: translateY(-100%);
  }
}
@-webkit-keyframes scaleUpFrom {
  from {
    opacity: 0;
    -webkit-transform: scale(1.5);
  }
}
@-moz-keyframes scaleUpFrom {
  from {
    opacity: 0;
    -moz-transform: scale(1.5);
  }
}
@keyframes scaleUpFrom {
  from {
    opacity: 0;
    transform: scale(1.5);
  }
}
@-webkit-keyframes scaleUpTo {
  to {
    opacity: 0;
    -webkit-transform: scale(1.5);
  }
}
@-moz-keyframes scaleUpTo {
  to {
    opacity: 0;
    -moz-transform: scale(1.5);
  }
}
@keyframes scaleUpTo {
  to {
    opacity: 0;
    transform: scale(1.5);
  }
}
